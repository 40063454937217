// @TODO: This should be a shared component!!!!!

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledPageSectionHeader } from "UI/components/structure/PageSectionHeader";
import { Button, StyledButtonWrapper } from "UI/elements/form/Button";
import { Link } from "UI/elements/navigation/Link";
import Confetti from "UI/components/Confetti.jsx";

const Confirmation = ({
  header,
  dek,
  icon,
  buttonText,
  action,
  showConfetti,
  secondaryActionText,
  secondaryAction,
  secondaryActionColor,
}) => {
  const [showsConfetti, setShowsConfetti] = useState(false);
  useEffect(() => {
    if (showConfetti) {
      setShowsConfetti(true);
    }
    scrollToTop();
  }, [showConfetti]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    document.getElementById("bodyWrapper").scrollTop = 0;
  };

  return (
    <StyledConfirmationWrapper>
      <StyledPageSectionHeader maxWidth={900} bottom={40} centered>
        {icon && <div className={"iconWrapper"}>{icon}</div>}
        <h4>{header}</h4>
        <p>{dek}</p>
      </StyledPageSectionHeader>
      <StyledButtonWrapper>
        <Button
          handleClick={action}
          title={buttonText}
          style={"-flexible"}
          bottom={20}
        />
      </StyledButtonWrapper>
      {secondaryActionText && (
        <Link
          text={secondaryActionText}
          size={18}
          navigate={secondaryAction}
          color={secondaryActionColor || null} //'#d8302e'
        />
      )}
      <Confetti showConfetti={showsConfetti} />
    </StyledConfirmationWrapper>
  );
};

export const StyledConfirmationWrapper = styled.div`
  position: relative;
  flex: 1;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  margin: 41px auto;
  max-width: 980px;

  p strong {
    font-weight: bold;
  }
  .iconWrapper {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: 480px) {
    margin: 40px auto;
    padding-bottom: 40px;
    button {
      width: 100%;
      max-width: 100%;
    }
  }
  .iconWrapper {
    width: 80px;
    height: 80px;
  }
`;

export default Confirmation;
