import React from "react";
import styled from "styled-components";

type CurrentPaymentMethodBlockProps = {
  cardType?: string;
  last4?: number | string | null;
  align?: string;
  bottom?: number;
};

const CurrentPaymentMethodBlock: React.FC<CurrentPaymentMethodBlockProps> = ({
  cardType,
  last4,
  align = "left",
  bottom = 0,
}) => {
  const showLast4 = Boolean(last4);

  const getCardImage = (type: string | undefined) => {
    if (type) {
      let cardImage = "";

      switch (type) {
        case "Visa":
          cardImage = "Visa_Inc._logo.svg";
          break;
        case "MasterCard":
          cardImage = "Mastercard-logo.svg";
          break;
        case "Discover":
          cardImage = "discover-logo.svg";
          break;
        case "American Express":
          cardImage = "amex-logo.svg";
          break;
      }

      if (!cardImage) {
        return null; //catch all
      }
      return <img src={`//static.bookofthemonth.com/elements/${cardImage}`} />;
    } else {
      return null;
    }
  };

  return (
    <StyledPaymentBlocWrapper align={align} bottom={bottom}>
      {getCardImage(cardType)}
      {showLast4 ? <p>&bull;&bull;&bull;&bull; {last4}</p> : null}
    </StyledPaymentBlocWrapper>
  );
};

interface IStyledPaymentBlocWrapper {
  align?: string;
  bottom?: number;
}

export const StyledPaymentBlocWrapper = styled.div.attrs({
  className: "sc-StyledPaymentBlocWrapper" as string,
})<IStyledPaymentBlocWrapper>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: ${(props) =>
    props.align === "right" ? "flex-end" : "flex-start"};
  align-items: center;
  margin-bottom: ${(props) => props.bottom}px;
  img {
    height: 24px;
  }
  p {
    margin-left: 8px;
  }
  @media (max-width: 640px) {
    img {
      height: 20px;
    }
  }
`;

export default CurrentPaymentMethodBlock;
