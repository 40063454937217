/**
 * Parent wrapper for site modals.
 * Child of BOM.jsx
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { setOverlay } from "State/ui/creators";

// import EmailCaptureModal from 'UI/components/EmailCaptureModal.jsx';
import LoyaltyModal from "UI/pages/loyalty/LoyaltyModal.jsx";
import LoyaltyBirthdayMonthModal from "UI/pages/loyalty/LoyaltyBirthdayMonthModal.jsx";
import GateModal from "UI/components/GateModal.jsx";

import JoinAddModal from "UI/components/JoinAddModal.jsx";
import SignUpModal from "UI/components/SignUpModal.jsx";

import TrackSwitchModal from "UI/components/TrackSwitchModal.jsx";
import AddressValidationModal from "UI/components/AddressValidationModal.jsx";

import AvatarEditWrapper from "UI/components/account/AvatarEditWrapper.jsx";
import EditAccountWrapper from "UI/components/account/EditAccountWrapper.jsx";
import EmailPrefsEditWrapper from "UI/components/account/EmailPrefsEditWrapper.jsx";
import NonCancelModal from "UI/pages/cancel_pause/NonCancelModal";
import RecommendBookModal from "UI/pages/raf/RecommendBookModal.jsx";
import ListSortModalWrapper from "UI/components/ListSortModalWrapper.jsx";
import UpgradeMembershipConfModal from "UI/pages/post_join/UpgradeMembershipConfModal.jsx";
import WishlistHighlightModal from "UI/pages/wishlist/WishlistHighlightModal.jsx";
import BanModal from "UI/components/BanModal.tsx";
import GiftPreviewModal from "UI/pages/gift/purchase/GiftPreviewModal";

import HolidayModal from "UI/components/holiday/HolidayModal.jsx";
import BffExperimentModal from "UI/components/holiday/BffExperimentModal.jsx";
import GiftRenewalModal from "UI/pages/gift/rejoin/GiftRenewalModal";
import ShipBillingModal from "UI/components/ShipBillingModal.jsx";
import OccupationModal from "UI/components/OccupationModal";
import RateGuidelinesModal from "UI/pages/bookshelf/RateGuidelinesModal.jsx";
import { Modal } from "UI/containers/Modal";
import AgeVerificationModal from "UI/components/account/AgeVerificationModal";
import SkipSurvey from "UI/components/SkipSurvey";
import LoyaltyTierConfModal from "UI/components/relationship_status/LoyaltyTierConfModal.jsx";
import { ConnectedPostCycleModal } from "UI/components/PostCycleAudiobooksModal";
import BirthdayModal from "UI/components/BirthdayModal";

class SiteOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollit: false,
    };
    // this.thisModal = React.forwardRef();
    this.removeOverlay = this.removeOverlay.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.overlay?.name !== "BanModal"
    ) {
      this.removeOverlay();
    }
    if (this.props.overlay !== prevProps.overlay && !this.props.overlay.name) {
      this.freezeBody(false);
    }
  }

  componentWillUnmount() {
    this.freezeBody(false);
  }

  freezeBody(freeze) {
    if (typeof document !== "undefined") {
      if (freeze) {
        document.getElementById("bodyWrapper").className = "modalOpen";
      } else {
        document.getElementById("bodyWrapper").className = "modalClosed";
      }
    }
  }

  removeOverlay() {
    this.freezeBody(false);
    this.props.setOverlay(null);
  }

  scrollUp() {
    this.setState({
      scrollit: !this.state.scrollit,
    });
  }

  render() {
    const { overlay, location } = this.props;

    let contentBody = <div />;
    let showOverlay =
      overlay && overlay.name && overlay.type != "special" ? true : false;
    let noClose = false;
    let isNonStandard = false;
    let adaClass;
    if (showOverlay) {
      this.freezeBody(true);
      switch (overlay.name) {
        // case 'emailCapture':
        //   contentBody = <EmailCaptureModal {...overlay.data} removeOverlay={this.removeOverlay} />;
        //   break;
        case "loyalty":
          contentBody = (
            <LoyaltyModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "pdpGate":
          noClose = true;
          contentBody = (
            <GateModal {...overlay.data} removeOverlay={this.removeOverlay} />
          );
          break;
        case "joinAddModal":
          noClose = false;
          contentBody = (
            <JoinAddModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "signUpModal":
          noClose = false;
          contentBody = (
            <SignUpModal {...overlay.data} removeOverlay={this.removeOverlay} />
          );
          adaClass = overlay.type == "joinNow" ? "-joinNow" : "-signUp";
          break;
        case "trackSwitch":
          contentBody = (
            <TrackSwitchModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "freeBotyModal":
          isNonStandard = true;
          contentBody = (
            <FreeBotyModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "editAvatar":
          contentBody = <AvatarEditWrapper />;
          break;
        case "editAccount":
          contentBody = <EditAccountWrapper {...overlay.data} />;
          break;
        case "editEmailPrefs":
          contentBody = <EmailPrefsEditWrapper />;
          break;
        case "nonCancelModal":
          noClose = ["/cancel-pause"].includes(location);
          contentBody = <NonCancelModal />;
          break;
        case "addressValidationModal":
          noClose = true;
          contentBody = (
            <AddressValidationModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "recommendBookModal":
          contentBody = (
            <RecommendBookModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
              scrollUp={this.scrollUp}
            />
          );
          break;
        case "upgradeMembershipConf":
          contentBody = (
            <UpgradeMembershipConfModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "listSort":
          contentBody = (
            <ListSortModalWrapper
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "LoyaltyBirthdayMonthModal":
          isNonStandard = true;
          contentBody = (
            <LoyaltyBirthdayMonthModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "WishlistHighlightModal":
          isNonStandard = true;
          noClose = false;
          contentBody = (
            <WishlistHighlightModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "GiftPreview":
          contentBody = (
            <GiftPreviewModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "BanModal":
          isNonStandard = false;
          noClose = true;
          contentBody = (
            <BanModal {...overlay.data} removeOverlay={this.removeOverlay} />
          );
          break;
        case "GiftRenewalModal":
          isNonStandard = true;
          noClose = true;
          contentBody = (
            <GiftRenewalModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "HolidayModal":
          isNonStandard = true;
          noClose = false;
          contentBody = (
            <HolidayModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "BffExperimentModal":
          isNonStandard = true;
          noClose = false;
          contentBody = (
            <BffExperimentModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;

        case "ShipBillingModal":
          isNonStandard = false;
          noClose = false;
          contentBody = (
            <ShipBillingModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;

        case "RateGuidelinesModal":
          isNonStandard = false;
          noClose = false;
          contentBody = (
            <RateGuidelinesModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;

        case "AgeVerificationModal":
          isNonStandard = true;
          noClose = true;
          contentBody = (
            <AgeVerificationModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;

        case "SkipSurveyModal":
          isNonStandard = true;
          noClose = true;
          contentBody = (
            <SkipSurvey {...overlay.data} removeOverlay={this.removeOverlay} />
          );
          break;

        case "LoyaltyTierConfModal":
          isNonStandard = true;
          noClose = false;
          contentBody = (
            <LoyaltyTierConfModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "OccupationModal":
          contentBody = (
            <OccupationModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        case "PostCycleAudiobooksModal":
          return (
            <ConnectedPostCycleModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
              showOverlay={showOverlay}
            />
          );
        case "BirthdayModal":
          contentBody = (
            <BirthdayModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
          break;
        default:
          contentBody = (
            <FreeBotyModal
              {...overlay.data}
              removeOverlay={this.removeOverlay}
            />
          );
      }
    }
    return (
      <Modal
        showing={showOverlay ? true : false}
        handleClose={this.removeOverlay}
        noClose={noClose}
        scrollUp={this.scrollUp}
        scrollit={this.state.scrollit}
        isNonStandard={isNonStandard}
        adaClass={adaClass}
      >
        {contentBody}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    location: state.analytics.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteOverlay);
