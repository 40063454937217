import globalScope from "../../global"; // For tests, ugh

export async function all() {
  const response = await fetch(`${global.apiHost}/api/app_feature`, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function get(id: number) {
  const response = await fetch(`${global.apiHost}/api/v0/app_feature/${id}`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function createBetaByFeature(appFeatureId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/app_feature/createBetaByFeature`,
    {
      method: "POST",
      body: JSON.stringify({ appFeatureId }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return await response.json();
}

export async function deleteBetaByFeature(appFeatureId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/app_feature/deleteBetaByFeature`,
    {
      method: "delete",
      body: JSON.stringify({ appFeatureId }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}
