import { COLORS } from "../../../css/Consts";
import * as React from "react";

interface IEmotionalIcon {
  handleClick?: (...args: any) => void;
  color?: string; //backgroundColor
  width?: number;
  height?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}
export const EmotionalIcon: React.FC<IEmotionalIcon> = ({
  color = COLORS.blue, //backgroundColor
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  width = 64,
  height = 64,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 100 100"
    fill="none"
    style={{
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left,
    }}
  >
    <path
      d="M82.3435 43.9062C81.2497 40.3125 82.4997 42.0312 80.781 39.0625C77.3435 29.5312 75.6248 22.8125 66.8748 17.8125C63.9061 16.0938 56.8748 14.0625 52.6561 14.0625C35.7812 14.0625 27.1875 19.375 20.4687 30.625C10.625 47.6562 16.25 69.2187 33.1249 79.2187C38.5937 82.5 44.6874 84.0625 50.7811 84.0625C52.9686 86.0938 56.0936 87.3438 59.6873 87.3438C66.0935 87.3438 71.2498 83.2812 71.2498 78.4375C71.2498 73.5937 71.2498 78.2812 71.2498 78.125C79.0623 72.6562 82.9685 64.375 83.281 55.625C83.5935 51.7188 83.1247 46.4062 82.3435 44.2188V43.9062Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.75 48.5938C18.75 30.5433 33.3557 15.9375 51.4061 15.9375C69.4565 15.9375 84.0622 30.5433 84.0622 48.5938C84.0622 59.7529 78.4803 69.599 69.9517 75.5034L68.1729 72.9341C75.8942 67.5885 80.9373 58.6846 80.9373 48.5938C80.9373 32.2692 67.7306 19.0625 51.4061 19.0625C35.0816 19.0625 21.875 32.2692 21.875 48.5938C21.875 64.6791 34.6974 77.7372 50.6904 78.1165C50.8288 78.0316 50.9727 77.9694 51.1134 77.9272C51.6518 77.7657 52.1634 77.8948 52.3184 77.9339L52.332 77.9373C52.4464 77.9659 52.6851 78.0367 52.8068 78.0728C52.8444 78.084 52.8708 78.0918 52.879 78.0941C52.9731 78.1202 53.0112 78.126 53.0111 78.1265C53.0111 78.1268 52.9953 78.125 52.9686 78.125V81.25C52.9686 81.25 52.9687 81.25 52.9686 81.25H51.4061C33.3557 81.25 18.75 66.6442 18.75 48.5938Z"
      fill={COLORS.black}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.5986 46.5997C31.441 46.4125 32.2756 46.9436 32.4628 47.786C32.941 49.9376 34.8488 51.5625 37.0313 51.5625C39.2712 51.5625 41.1343 50.0364 41.5997 47.9423C41.7869 47.0999 42.6215 46.5687 43.4639 46.7559C44.3063 46.9431 44.8375 47.7778 44.6503 48.6202C43.8656 52.151 40.7288 54.6875 37.0313 54.6875C33.2762 54.6875 30.1841 51.9373 29.4122 48.4639C29.225 47.6215 29.7562 46.7869 30.5986 46.5997Z"
      fill={COLORS.black}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.036 46.5997C54.8784 46.4125 55.713 46.9436 55.9002 47.786C56.3784 49.9377 58.2862 51.5625 60.4687 51.5625C62.7086 51.5625 64.5717 50.0364 65.0371 47.9423C65.2243 47.0999 66.0589 46.5687 66.9013 46.7559C67.7437 46.9431 68.2749 47.7778 68.0877 48.6202C67.303 52.151 64.1662 54.6875 60.4687 54.6875C56.7136 54.6875 53.6215 51.9373 52.8497 48.4639C52.6625 47.6215 53.1936 46.7869 54.036 46.5997Z"
      fill={COLORS.black}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.9388 70.7555C53.1951 72.4558 52.8124 73.8175 52.8124 74.6875C52.8124 78.6683 56.0191 81.875 59.9999 81.875C63.9807 81.875 67.1874 78.6683 67.1874 74.6875C67.1874 73.8175 66.8047 72.4558 66.061 70.7555C65.3394 69.1057 64.3568 67.3108 63.3348 65.6393C62.3142 63.9701 61.2739 62.456 60.4515 61.3712C60.2894 61.1572 60.1383 60.9635 59.9999 60.7912C59.8615 60.9635 59.7105 61.1572 59.5483 61.3712C58.7259 62.456 57.6856 63.9701 56.665 65.6393C55.643 67.3108 54.6605 69.1057 53.9388 70.7555ZM60.7674 59.9264C60.7712 59.9233 60.7715 59.9232 60.7674 59.9264V59.9264ZM59.1383 57.2653C59.2458 57.2046 59.5655 57.0312 59.9999 57.0312C60.4343 57.0312 60.754 57.2046 60.8615 57.2653C61.0098 57.349 61.1331 57.4432 61.2214 57.5161C61.4014 57.6646 61.5816 57.8462 61.7484 58.0268C62.0893 58.3962 62.4988 58.8989 62.9418 59.4833C63.8333 60.6592 64.9317 62.2604 66.0009 64.0091C67.0688 65.7556 68.1272 67.6813 68.9241 69.5032C69.6989 71.2746 70.3124 73.1356 70.3124 74.6875C70.3124 80.3942 65.7066 85 59.9999 85C54.2932 85 49.6875 80.3942 49.6875 74.6875C49.6875 73.1356 50.3009 71.2746 51.0757 69.5032C51.8726 67.6813 52.9311 65.7556 53.9989 64.0091C55.0681 62.2604 56.1665 60.6592 57.058 59.4833C57.501 58.8989 57.9105 58.3962 58.2515 58.0268C58.4182 57.8462 58.5985 57.6646 58.7784 57.5161C58.8667 57.4432 58.99 57.349 59.1383 57.2653Z"
      fill={COLORS.black}
    />
  </svg>
);
