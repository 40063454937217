import React from "react";
import styled from "styled-components";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import Authenticate from "../../../ui/skeleton/Authenticate";
import { useWindowSize } from "../../../ui/hooks";
import { MEDIAQUERIES } from "../../../css/Consts";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { IAccount } from "../../../../../types/account";
import { connect } from "react-redux";
import { snesRedirect } from "../../../utils/snesRedirect";

type SavedConfirmationProps = {} & SavedConfirmationMappedProps;

const SavedConfirmation: React.FC<SavedConfirmationProps> = ({ account }) => {
  const [width] = useWindowSize();
  const isMobile = width <= 680;
  const { lastPlan } = account || {};
  const { price } = lastPlan || {};

  const keepMe = async () => {
    snesRedirect("snes", "/the-best-new-books");
  };

  return (
    <StyledSavedConfirmation>
      <Authenticate>
        <StyledFlexWrapper
          id={`SavedConfirmation`}
          paddingTop={isMobile ? 40 : 80}
          paddingLeft={isMobile ? 20 : 0}
          paddingRight={isMobile ? 20 : 0}
          paddingBottom={isMobile ? 80 : 120}
          maxWidth={552}
          justifyContent="center"
          alignItems="center"
        >
          <div className="iconContainer">
            <img
              src={`//static.bookofthemonth.com/Bookmojis/Second_in_Series.svg`}
              className="icon"
            />
          </div>
          <h4 className="header">Thanks for sticking around!</h4>
          <div className="copy">
            Your next renewal will be for $9.99 for a 1 credit plan! You will
            return to your ${price} plan after you use your offer.
          </div>
          <ClickTracker
            ctaType={"button"}
            id="browse-the-books"
            style={`primary ${isMobile ? "-fullWidth" : ""}`}
            handleClick={keepMe}
            title={"Browse the books"}
            logClickData={"credit_commit_offer_browse_books"}
            top={40}
            minWidth={isMobile ? 0 : 228}
          />
        </StyledFlexWrapper>
      </Authenticate>
    </StyledSavedConfirmation>
  );
};

export const StyledSavedConfirmation = styled.div`
  .iconContainer {
    width: 100px;
    height: 100px;
  }

  .icon {
    width: 100%;
  }

  .header,
  .copy {
    padding-top: 20px;
    text-align: center;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    .header,
    .copy {
      padding-top: 12px;
    }
  }
  .iconContainer {
    width: 80px;
    height: 80px;
  }
`;

type SavedConfirmationMappedProps = {
  account?: IAccount;
};

const mapStateToProps = ({ account }: SavedConfirmationMappedProps) => {
  return {
    account: account,
  };
};

export default connect(mapStateToProps, null)(SavedConfirmation);
