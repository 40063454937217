import React, { useEffect } from "react";
import { AppState } from "../../../types";
import getGracePeriodCancelDate from "../../../utils/getGracePeriodCancelDate";
import { connect } from "react-redux";
import { useWindowSize } from "../../../ui/hooks";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import { EmotionalIcon } from "../../../ui/elements/icons/EmotionalIcon";
import dateformat from "dateformat";
import CancelLanding from "../cancel_membership/CancelLanding";
import RelationshipStatus from "../cancel_membership/RelationshipStatus";

type CancelConfirmationProps = {
  showPerks?: boolean;
} & MappedCancelConfirmationProps;
const CancelConfirmation: React.FC<CancelConfirmationProps> = ({
  account,
  showPerks = false,
}) => {
  const expirationDate = getGracePeriodCancelDate(account?.policyEffectiveDate);
  const credits = account?.credits || 0;
  const [width] = useWindowSize();
  const isMobile = width < 680;

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    const bodyWrapper = document.getElementById("bodyWrapper");
    if (bodyWrapper) {
      bodyWrapper.scrollTop = 0;
    }
  };

  const setSubsectionContent = () => {
    if (showPerks) {
      return (
        <StyledFlexWrapper
          borderTop={true}
          paddingTop={40}
          marginTop={40}
          customStyles={customRelationshipStatusStyles}
        >
          <RelationshipStatus />
        </StyledFlexWrapper>
      );
    } else {
      return (
        <StyledFlexWrapper
          paddingTop={56}
          marginTop={56}
          borderTop={true}
          customStyles={customCancelLandingStyles}
        >
          <CancelLanding showNORPauseCancelExp134={true} />
        </StyledFlexWrapper>
      );
    }
  };

  const hed = "Your membership has been successfully canceled.";
  const dek =
    credits >= 1 && expirationDate
      ? `Your remaining ${credits} credit${
          credits > 1 ? "s" : ""
        } will expire on ${dateformat(expirationDate, "mmm d, yyyy")}.`
      : `Maybe we'll catch you around? We can only hope. Bye for now! `;
  return (
    <StyledFlexWrapper
      paddingTop={isMobile ? 40 : 56}
      paddingBottom={isMobile ? 80 : 120}
      maxWidth={480}
      paddingLeft={isMobile ? 20 : 0}
      paddingRight={isMobile ? 20 : 0}
    >
      <StyledFlexWrapper maxWidth={80}>
        <EmotionalIcon
          height={isMobile ? 80 : 100}
          width={isMobile ? 80 : 100}
        />
      </StyledFlexWrapper>
      <StyledFlexWrapper
        resetSpacing
        maxWidth={480}
        textAlign="center"
        marginTop={isMobile ? 12 : 20}
        gap={isMobile ? 12 : 20}
      >
        <h4>{hed}</h4>
        {dek && <p>{dek}</p>}
      </StyledFlexWrapper>
      {setSubsectionContent()}
    </StyledFlexWrapper>
  );
};

const customCancelLandingStyles = `
  .postEnrollSurvey, #StyledStepWrapper{
    padding:0 !important;
  }`;

const customRelationshipStatusStyles = `
  .sc-RelationshipStatusWrapper{
    padding: 0;
  }
`;

type MappedCancelConfirmationProps = Pick<AppState, "account">;

const mapStateToProps = (state: AppState): MappedCancelConfirmationProps => {
  return {
    account: state.account,
  };
};
export default connect(mapStateToProps)(CancelConfirmation);
