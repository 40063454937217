/**
 * Used to display renewal message for enroll
 * Child of Billing.jsx
 */

import React, { useContext } from "react";
import { connect } from "react-redux";
import dateformat from "dateformat";
import { useStoreData } from "UI/hooks";
import { countryFuturePlanPrice, countryPlanPrice } from "Utils/countryPricing";
import { RegionContext } from "UI/contexts/RegionContext";
import { getPricing, isValidDate } from "Utils/index";
import { Variant } from "../../ui/skeleton/Experiments";
import { priceToShow } from "Utils/priceToShow";
import { useCreditCommit } from "UI/contexts/CreditCommitContext";

const AutoRenewFinePrint = ({
  account,
  planPromo,
  policy,
  customPlan,
  showG2MFinePrint, // showG2MFinePrint flag is used to show the fine print for G2M
  showingTerms,
  isMobile,
  showNegativeOptionRuleFinePrint,
  isForPlanChange,
  exp147,
  exp138,
  ...props
}) => {
  const { getCycleRelative, store } = useStoreData();
  const { tranche, pickingPeriodOpen } = store;
  const { locationHeader } = useContext(RegionContext);
  const { wasCreditCommitMember, creditCommitPolicyCycles } = useCreditCommit();

  if (props.joinFlow === "join" && props.enrollStep === "shipping") {
    return <div />;
  }
  if (!props.plan) {
    return <div />;
  }

  const { plan, joinData } = props || {};
  const countryCode = (() => {
    if (account?.countryCode) {
      return account.countryCode;
    } else {
      return locationHeader.countryHeader;
    }
  })();
  let text;
  let appendedText;

  const planPrice = priceToShow(countryPlanPrice(plan, countryCode));
  const promoValue = priceToShow(joinData?.promo?.value);

  // appendedText conditions
  const hasMustRenewEnrollment =
    !!joinData?.promo?.policy?.mustRenew || !!plan?.mustRenew;
  const isNotRejoinCommit = !exp138;
  const isBTVerification = exp147 == 1;
  const isNotCanada = countryCode !== "CA";
  const isEnroll = ["Lead", "User", "Visitor"].includes(account?.policy?.type);
  const showBtVerification =
    isNotCanada && isBTVerification && isNotRejoinCommit && isEnroll;

  // appendedText constants
  const mustRenewEnrollmentText = hasMustRenewEnrollment
    ? ` As part of your ${
        promoValue || planPrice
      } enrollment offer, you acknowledge that you cannot cancel until after you are renewed at least once.`
    : ``;

  const btVerificationText = showBtVerification
    ? ` As part of this transaction, you may see a $16.99 temporary hold on your payment method, which will be released in a couple of days.`
    : ``;

  const renewalPlanPrice = props?.joinData?.plan
    ? countryFuturePlanPrice(props?.joinData?.plan || {}, countryCode)
    : countryFuturePlanPrice(plan || {}, countryCode);

  const expirationDate = new Date(account?.renewalDate);
  const yearlyExtendedExpirationDate = expirationDate
    ? new Date(expirationDate)
    : null;
  yearlyExtendedExpirationDate &&
    yearlyExtendedExpirationDate.setFullYear(
      yearlyExtendedExpirationDate.getFullYear() + 1,
    );

  const isGiftToMember =
    account?.policy?.type == "Member" &&
    account?.policy?.subType == "Gift" &&
    (account?.renewalPlan?.mustRenew || !account?.renewalPlan);
  const isCCFail = policy?.subType == "CC Fail";
  const isGiftRejoin =
    account?.policy?.type == "Rejoin" && account?.policy?.subType == "Gift";
  const isRejoinACF =
    account?.policy?.type == "Rejoin" &&
    ["Rejoin A", "Rejoin C", "Rejoin F"].includes(
      account?.policy?.subType || "",
    );

  let ccFailCreditCommitText = "";
  if (isCCFail && wasCreditCommitMember && creditCommitPolicyCycles > 1) {
    if (creditCommitPolicyCycles === 2) {
      ccFailCreditCommitText = ` As part of your enrollment offer, you acknowledge that you cannot cancel until your next renewal.`;
    }
  }

  if (!renewalPlanPrice) {
    return <div />;
  }

  if (isValidDate(expirationDate)) {
    let expireDate;
    try {
      expireDate = dateformat(expirationDate, "UTC:mm/dd/yyyy");
    } catch {
      console.error("TCL: expirationDate", expirationDate);
    }
    if (isCCFail && !showNegativeOptionRuleFinePrint) {
      expireDate = dateformat(expirationDate, "dS");
      text = `You will be renewed today for $${renewalPlanPrice} + tax.`;
    } else if (showNegativeOptionRuleFinePrint) {
      const _renewalPlan = plan || {};
      const _renewalPlanPrice = countryFuturePlanPrice(
        _renewalPlan,
        countryCode,
      );
      const currentMonth = new Date().toLocaleString("en-US", {
        month: "long",
      });
      const currentYearPlusOne = new Date().getFullYear() + 1;
      if (isGiftToMember) {
        const renewalDate = dateformat(
          account?.renewalDate,
          "UTC:mmmm dS, yyyy",
        );
        const renewalDateInDateObj = new Date(account?.renewalDate);

        const datePlusOneMonth = new Date(
          renewalDateInDateObj?.setMonth(renewalDateInDateObj.getMonth() + 1),
        );
        const renewalDatePlusOneMonth = dateformat(
          datePlusOneMonth,
          "UTC:mmmm dS, yyyy",
        );
        const g2mPromoPrice = countryPlanPrice(
          account?.policy?.planSet?.tracks[1][0],
          account?.countryCode,
        );
        const futurePlanPrice = countryFuturePlanPrice(
          _renewalPlan,
          account?.countryCode,
        );
        if (/monthly/i.test(_renewalPlan.name)) {
          text = ` You also acknowledge that you are signing up for a recurring monthly subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st of each month, you will be renewed for $${g2mPromoPrice} plus tax on ${renewalDate} and $${futurePlanPrice} starting on ${renewalDatePlusOneMonth} and one credit will be added to your account each month redeemable for your next book.`;
        } else if (/annual/i.test(_renewalPlan.name)) {
          text = ` You also acknowledge that you are signing up for a recurring monthly subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st of each month, you will be renewed for $${g2mPromoPrice} plus tax on ${renewalDate} and one credit will be added to your account. On ${renewalDatePlusOneMonth} you will be charged $${futurePlanPrice} and 12 credits will be added to your account redeemable for your next 12 books.`;
        }
      } else if (_renewalPlan.credits == 1 && _renewalPlan.cycles == 1) {
        text = ` You also acknowledge that you are signing up for a recurring monthly subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st of each month, you will be renewed for $${_renewalPlanPrice} plus tax on ${dateformat(
          expirationDate,
          "UTC:mmmm dS, yyyy",
        )} and one credit will be added to your account redeemable for your next book.`;
      } else if (_renewalPlan.credits == 2 && _renewalPlan.cycles == 1) {
        text = ` You also acknowledge that you are signing up for a recurring monthly subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st of each month, you will be renewed for $${_renewalPlanPrice} plus tax on ${dateformat(
          expirationDate,
          "UTC:mmmm dS, yyyy",
        )} and two credits will be added to your account redeemable for your next two books.`;
      } else if (_renewalPlan.credits >= 12 && _renewalPlan.cycles >= 1) {
        text = ` You also acknowledge that you are signing up for a recurring annual subscription. Unless you skip the month or cancel your membership using our site or app prior to the 21st day of ${currentMonth} ${currentYearPlusOne}, you will be renewed for $${_renewalPlanPrice} plus tax on ${dateformat(
          yearlyExtendedExpirationDate,
          "UTC:mmmm dS, yyyy",
        )} and 12 credits will be added to your account redeemable for your next 12 books.`;
      }
    } else if (showG2MFinePrint) {
      const { PRICE } = getPricing(customPlan, countryCode, account);
      text = `Once your gift ends, you will be renewed at $${PRICE} for the first month and then ${
        plan?.name?.search("Monthly") === -1
          ? `$${renewalPlanPrice} for 12 credits`
          : `$${renewalPlanPrice} per month`
      } after that.`;
    } else {
      if (plan.cycles === 1 && plan.credits === 1) {
        text = `Unless you skip the month or cancel your membership, you will be renewed on the ${dateformat(
          expireDate,
          "UTC:dS",
        )} of each month for $${renewalPlanPrice} plus tax and 1 credit will be added to your account redeemable for your next book.`;
      } else if (plan.cycles === 1 && plan.credits > 1) {
        text = `Unless you skip the month or cancel your membership, you will be renewed on the ${dateformat(
          expireDate,
          "UTC:dS",
        )} of each month for $${renewalPlanPrice} plus tax and ${
          plan.credits
        } credits will be added to your account redeemable for your next books.`;
      } else if (plan.cycles === 12) {
        text = `Unless you skip the month or cancel your membership, you will be renewed on ${dateformat(
          expireDate,
          "UTC:mmmm dS, yyyy",
        )} for $${renewalPlanPrice} plus tax and ${
          plan.credits
        } credits will be added to your account redeemable for your next books.`;
      } else {
        return <div />;
      }
    }
    appendedText = `${
      mustRenewEnrollmentText + ccFailCreditCommitText + btVerificationText
    }`;
  }
  return <>{text + appendedText}</>;
};

const AutoRenewFinePrintExp = (props) => (
  <Variant experimentId={138}>
    {({ variant }) => <AutoRenewFinePrintExp147 {...props} exp138={variant} />}
  </Variant>
);

const AutoRenewFinePrintExp147 = (props) => (
  <Variant experimentId={147}>
    {({ variant }) => <AutoRenewFinePrint {...props} exp147={variant} />}
  </Variant>
);

function mapStateToProps(state, ownProps) {
  let plan =
    ownProps.customPlan ||
    ownProps.overridePlan ||
    state.joinData.plan ||
    state.account?.renewalPlan;
  let planPromo = ownProps.overridePlan
    ? ownProps.overridePlan.promo
    : state.joinData.promo;
  return {
    account: state.account,
    joinFlow: state.joinFlow,
    enrollStep: state.joinData.enrollStep,
    plan: plan,
    planPromo: planPromo,
    policy: state.policy,
    joinData: state.joinData,
  };
}

export default connect(mapStateToProps)(AutoRenewFinePrintExp);
