import React from "react";
import { Route, Redirect } from "react-router-dom";
import loadable from "@loadable/component";

import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import FormPageSkeleton from "UI/loadingSkeletons/FormPageSkeleton.jsx";
import UserFlowsSkeleton from "UI/loadingSkeletons/UserFlowsSkeleton.jsx";

const ReferFriend = loadable(() => import("UI/pages/raf/ReferFriend.tsx"), {
  fallback: <LoadingCoinSkeleton />,
});
const BookshelfWrapper = loadable(
  () => import("UI/pages/bookshelf/BookshelfWrapper.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const AccountPage = loadable(() => import("UI/pages/account/AccountPage.jsx"), {
  fallback: <LoadingCoinSkeleton />,
});

const OrdersPage = loadable(() => import("UI/pages/account/OrdersPage.jsx"), {
  fallback: <LoadingCoinSkeleton />,
});
const MyPlanPage = loadable(() => import("UI/pages/account/MyPlanPage.jsx"), {
  fallback: <LoadingCoinSkeleton />,
});

const ForgotPassword = loadable(
  () => import("UI/pages/account/ForgotPassword.jsx"),
  {
    fallback: (
      <FormPageSkeleton
        numInput={1}
        className="login"
        buttonStyle="fullWidth"
        withButton={true}
      />
    ),
  },
);
const ChangePassword = loadable(
  () => import("UI/pages/account/ChangePassword.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const Wishlist = loadable(
  () => import("UI/pages/wishlist/WishlistLanding.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const AvatarEditPage = loadable(
  () => import("UI/components/account/AvatarEditPage.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const AgeRequirementPage = loadable(
  () => import("UI/components/account/AgeRequirementPage.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);

const pdpRegEx = "/:book([A-Za-z0-9-]+-[0-9]+)";

const Account = (() => [
  <Route exact path="/refer-a-friend" component={ReferFriend} />,
  <Route exact path="/my-account/account" component={AccountPage} />,
  <Route exact path="/my-account/orders" component={OrdersPage} />,
  <Route exact path="/my-account/membership" component={MyPlanPage} />,
  // Password
  <Route
    exact
    path={["/reset-password/:token", "/reset-password"]}
    component={ChangePassword}
  />,
  <Redirect from="/account" to="/my-account/account" />,
  <Route exact path="/forgot-password" component={ForgotPassword} />,
  // <Route path="/bookshelf" component={BookshelfWrapper} />,
  <Route exact path="/to-be-read" component={Wishlist} />,
  <Route
    path={["/bookshelf/:path?/:path2?", "/bookshelf"]}
    component={BookshelfWrapper}
  />,
  <Route exact path="/avatar" component={AvatarEditPage} />,
  <Route exact path="/age-requirement" component={AgeRequirementPage} />,
])();
export default Account;
