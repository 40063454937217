import globalScope from "../../global"; // For tests, ugh

export async function get(addressId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/address/${addressId}`,
    {
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const address = await response.json();
  return await address;
}
export async function create(address: any, originalChosen = false) {
  const response = await fetch(`${global.apiHost}/api/address/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...address, originalChosen }),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function validate(address: any) {
  const response = await fetch(`${global.apiHost}/api/address/validate`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(address),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const addresses = await response.json();
  return await addresses;
}
