import React from "react";
import { Route } from "react-router-dom";
import loadable from "@loadable/component";
import LoadingCoinSkeleton from "../ui/loadingSkeletons/LoadingCoinSkeleton";
import { snesRedirect } from "Utils/snesRedirect";

const HelpCenter = loadable(() => import("../ui/pages/help/HelpCenter"), {
  fallback: <LoadingCoinSkeleton />,
});
const Unskip = loadable(() => import("../ui/pages/help/Unskip"), {
  fallback: <LoadingCoinSkeleton />,
});
const CancelPause = loadable(() => import("../ui/pages/help/CancelPause"), {
  fallback: <LoadingCoinSkeleton />,
});
const HelpCenterCategory = loadable(
  () => import("../ui/pages/help/HelpCenterCategory"),
  { fallback: <LoadingCoinSkeleton /> },
);
const ArticlePage = loadable(() => import("../ui/pages/help/ArticlePage"), {
  fallback: <LoadingCoinSkeleton />,
});
const RequestForm = loadable(() => import("../ui/pages/help/RequestForm"), {
  fallback: <LoadingCoinSkeleton />,
});
const MessageReceivedConfirmation = loadable(
  () => import("../ui/pages/help/MessageReceivedConfirmation"),
  { fallback: <LoadingCoinSkeleton /> },
);

const HelpCenterRoutes = (() => [
  <Route
    path="/help-center"
    component={() => snesRedirect("snes", "/help-center")}
    exact
  />,
  <Route
    exact
    path="/help-center/unskip"
    component={() => snesRedirect("snes", "/help-center/unskip")}
  />,
  <Route
    exact
    path="/help-center/request-form"
    component={() => snesRedirect("snes", "/help-center/request-form")}
  />,
  <Route
    path="/help-center/*"
    component={() => snesRedirect("snes", "/help-center")}
  />,
  // we may need to implement redirects for more routes if we add help center pages in SNES while not updating the links in the Zendesk article
  // <Route exact path="/help-center/cancel-pause" component={CancelPause} />,
  // <Route
  //   exact
  //   path="/help-center/request-form/confirmation-message"
  //   component={MessageReceivedConfirmation}
  // />,
])();

export default HelpCenterRoutes;
