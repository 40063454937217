import globalScope from "../../global"; // For tests, ugh

export async function get(trancheId: number) {
  const response = await fetch(`${global.apiHost}/api/v0/tranche/${trancheId}`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const tranche = await response.json();
  return await tranche;
}

export async function getRenewalDate() {
  const response = await fetch(`${global.apiHost}/api/tranche`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const tranche = new Date(await response.json());
  return await tranche;
}
