type DocRef = string;
type Token = string;

interface SearchDocument {
  ref: string; // Unique string
  itemId: number;
  itemType: string;
  itemName?: string;
  tokenFrequency: TokenFrequency;
}

interface SearchResult {
  score: number;
  document: SearchDocument;
}

interface Indexable {
  id: number;
  type: string; // What type of document this is.
  fields: Map<string, string | Set<string>>; // Fields in the indexable
}

type TokenFrequency = Map<Token, number>;

type Tokens = Set<Token>;

/* Maps document references to a map of token frequencies */
type DocumentIndex = Map<DocRef, SearchDocument>;

/* Maps tokens to a map of frequencies by document reference */
type InverseDocumentFrequency = Map<Token, number>;

interface SearchIndex {
  idf: InverseDocumentFrequency;
  documentIndex: DocumentIndex;
  tokens: Tokens;
  stats: Map<string, number>;
}

enum InfluencerParent {
  Instagram = 'Instagram Influencer/Celebrity',
  Tiktok = 'TikTok Creators',
  Youtube = 'YouTube Creators',
}

const tokenize = (str: string): Token[] =>
  str
    .toLowerCase()
    .split(/[\s\-]+/)
    .map((tok) => tok.replace(/(^[^0-9a-z]+)|([^0-9a-z$])/g, ''))
    .filter((tok) => !!tok);

const levenshteinDistance = (string1: string, string2: string): number => {
  let n = string1.length,
    m = string2.length,
    temp: number;
  if (n === 0) return m;
  if (m === 0) return n;

  let nextCol: number;
  const prevRow = [];

  for (let i = 0; i <= m; i++) prevRow.push(i);

  for (let i = 0; i < n; ++i) {
    let j = 0;
    nextCol = i + 1;

    for (; j < m; ++j) {
      let curCol = nextCol;

      // substution
      let stringCompare = string1[i] === string2[j];
      nextCol = prevRow[j] + (stringCompare ? 0 : 1);

      // insertion
      temp = curCol + 1;
      if (nextCol > temp) {
        nextCol = temp;
      }
      // deletion
      temp = prevRow[j + 1] + 1;
      if (nextCol > temp) {
        nextCol = temp;
      }
      prevRow[j] = curCol;
    }
    prevRow[j] = nextCol;
  }
  return nextCol;
};

const scoreDocument = (
  index: SearchIndex,
  terms: string[],
  document: SearchDocument
): number => {
  let score = 0,
    mismatched = 1;
  for (let term of terms) {
    let match = false;
    for (let [docTerm, docFrequency] of document.tokenFrequency.entries()) {
      const isSubword = new RegExp(term).test(docTerm);
      const distance = isSubword
        ? docTerm.length - term.length
        : levenshteinDistance(term, docTerm);
      if (isSubword || distance <= 1) {
        score += docFrequency * index.idf.get(docTerm) * Math.pow(0.9, distance);
        match = true;
      }
    }
    /* Penalize the document if there is some term that does not match */
    if (!match) {
      mismatched += 1;
    }
  }
  return score * Math.pow(0.5, mismatched);
};

const searchIndex = (index: SearchIndex, searchTerm: string): SearchResult[] => {
  const terms = tokenize(searchTerm);
  const scores = Array.from(index.documentIndex.values()).map((document) => {
    let score = scoreDocument(index, terms, document);
    return { score, document };
  });
  scores.sort((a, b) => b.score - a.score);
  const avg = scores.reduce((s, { score }) => s + score, 0) / scores.length;
  const std = Math.sqrt(
    scores.reduce((s, { score }) => s + Math.pow(avg - score, 2), 0) / scores.length
  );
  // Use lower standard deviation threshold for smaller indexes
  let numOfStd = 5;
  if (scores.length < 20) {
    numOfStd = 0.5;
  } else if (scores.length < 200) {
    numOfStd = 2;
  }
  return scores.filter(({ score }) => score > avg + std * numOfStd);
};
/*
  We build an index using the tf-idf method.

  tf - Term Frequency. We adjust for length of document.
 */
const buildIndex = (indexables: Indexable[]): SearchIndex => {
  const documentIndex: DocumentIndex = new Map();
  const idf: InverseDocumentFrequency = new Map();
  const tokens: Tokens = new Set();
  const stats = new Map();

  const unitDocumentWeight = 1 / indexables.length;

  indexables.forEach((indexable) => {
    for (let [key, value] of indexable.fields.entries()) {
      const valueArray: string[] = [];
      if (value instanceof Set) {
        for (var subValue of value.values()) {
          valueArray.push(subValue);
        }
      } else {
        valueArray.push(value);
      }

      valueArray.forEach((stringValue, index) => {
        const documentTokens = tokenize(stringValue);
        const tokenFrequency: TokenFrequency = new Map();

        const unitTokenWeight = 1 / documentTokens.length;

        for (let token of documentTokens) {
          if (!tokenFrequency.has(token)) {
            const previousDocumentWeightValue = idf.get(token) || 0;
            idf.set(token, previousDocumentWeightValue + unitDocumentWeight);
          }
          const previousWeightValue = tokenFrequency.get(token) || 0;
          tokenFrequency.set(token, previousWeightValue + unitTokenWeight);
        }

        const document: SearchDocument = {
          itemId: indexable.id,
          itemType: indexable.type,
          itemName: stringValue,
          ref: `${indexable.type}:${indexable.id}:${key}:${index}`,
          tokenFrequency,
        };

        documentIndex.set(document.ref, document);

        if (!stats.has(document.itemType)) {
          stats.set(document.itemType, 0);
        }
        stats.set(document.itemType, stats.get(document.itemType) + 1);
      });
    }
  });

  for (let [key, value] of idf.entries()) {
    idf.set(key, Math.log(1 / value));
  }

  return { idf, documentIndex, tokens, stats };
};

export {
  buildIndex,
  searchIndex,
  scoreDocument,
  levenshteinDistance,
  InfluencerParent,
  Indexable,
  SearchIndex,
};
