import React, { useState, useReducer } from "react";
import { FAQCategories, Article } from "../../../../types/help_center";
import { AccountWithLoyaltyAndPayment } from "../../../../types/account";

import {
  getFaqs,
  sendHelpMessage as sendHelpMessageAPI,
} from "../../state/help/api";
import { useRegion } from "../hooks/useRegion";

import { convertToUrl, FormAction } from "../pages/help/util";
import { defaultForm } from "../pages/help/FormData";
import { FieldSet, ImageData } from "../elements/form";
import {
  mappedIssueTypes,
  convertUrlStringToStringWithSpaces,
} from "../pages/help/util";
import { subjectDropdownOptions } from "../pages/help/FormData";

import { flattenArray } from "../../utils";
import { formReducer } from "./HelpCenterReducer";

export type HelpCenterContextProps = {
  children?: JSX.Element;
  faqs: FAQCategories | null;
  getFAQs: () => void;
  isLoading: boolean;
  formState: FieldSet;
  setFormValue: (fieldName: string, v: string | ImageData) => void;
  sendHelpMessage: (
    msg: { [key: string]: string },
    recaptchaToken?: string | null,
  ) => void;
  setInitialIssueTypeAndSubjectFromQueryString: (
    issueTypeArg: string,
    subjectArg: string,
  ) => void;
  clearForm: () => void;
  searchFAQ: (searchTerm: string) => Article[];
  setNameAndEmailForMembers: (account?: AccountWithLoyaltyAndPayment) => void;
};

const initialFormState = { ...defaultForm };

const defaultState: HelpCenterContextProps = {
  faqs: null,
  getFAQs: () => {},
  isLoading: false,
  formState: initialFormState,
  setFormValue: () => {},
  sendHelpMessage: () => {},
  setInitialIssueTypeAndSubjectFromQueryString: () => {},
  clearForm: () => {},
  searchFAQ: () => [],
  setNameAndEmailForMembers: () => {},
};

const HelpCenterContext =
  React.createContext<HelpCenterContextProps>(defaultState);

const HelpCenterProvider: React.FC<[HelpCenterContextProps]> = ({
  children,
}) => {
  const { canadaMode } = useRegion();

  const [faqs, setFaqs] = useState<FAQCategories>({});
  const [isLoading, setIsLoading] = useState(false);

  // forms
  const [formState, setFormState] = useReducer(formReducer, initialFormState);

  const searchFAQ = (searchTerm: string) => {
    if (searchTerm) {
      const rgx = new RegExp(searchTerm, "i");
      return faqs
        ? flattenArray(Object.values(faqs)).filter(
            (question: Article) =>
              rgx.test(question.answer) || rgx.test(question.label),
          )
        : [];
    }
  };

  const clearForm = () => {
    setFormState({ type: FormAction.CLEAR_FORM, payload: { value: "" } });
  };

  const getFAQs = async () => {
    if (!isLoading && (!faqs || Object.entries(faqs).length == 0)) {
      try {
        const faqs_ = await getFaqs(canadaMode ? "en-au" : "en-gb");
        setIsLoading(false);
        if (faqs_) {
          setFaqs(mapArticleUrls(faqs_.credits));
        }
      } catch (e) {
        setIsLoading(false);
        console.warn("TCL: getReadingChallenge -> e", e);
      }
    }
  };

  const setFormValue = (fieldName: string, v: string | ImageData) => {
    if (fieldName == "issueType") {
      setFormState({
        type: FormAction.SET_ISSUE_TYPE,
        payload: { fieldName, value: v },
      });
    } else if (fieldName == "subject") {
      switch (v) {
        case "Where is my box":
          setFormState({
            type: FormAction.FIND_MY_BOX,
            payload: { fieldName, value: v, canadaMode },
          });
          break;
        case `Order marked as delivered but I didn't receive`:
          setFormState({
            type: FormAction.MARKED_AS_DELIVERED_NOT_RECEIVED,
            payload: { fieldName, value: v, canadaMode },
          });
          break;
        case "Birthday coupon not appearing":
          setFormState({
            type: FormAction.BFF_BIRTHDAY,
            payload: { fieldName, value: v },
          });
          break;
        case "Change address on existing order":
          setFormState({
            type: FormAction.CHANGE_ADDRESS,
            payload: { fieldName, value: v, canadaMode },
          });
          break;
        case "Issue with book":
          setFormState({
            type: FormAction.ISSUE_WITH_BOOK,
            payload: { fieldName, value: v },
          });
          break;
        default:
          setFormState({
            type: FormAction.DEFAULT_FORM,
            payload: { fieldName, value: v },
          });
          break;
      }
    } else {
      setFormState({
        type: FormAction.SET_FIELD_VALUE,
        payload: { fieldName, value: v },
      });
    }
  };

  const setInitialIssueTypeAndSubjectFromQueryString = (
    issueTypeArg: string,
    subjectArg: string,
  ) => {
    const issueTypeValueFound =
      Object.values(mappedIssueTypes).find(
        (t) =>
          t.toLowerCase() == convertUrlStringToStringWithSpaces(issueTypeArg),
      ) || "";

    if (issueTypeValueFound) {
      setFormValue("issueType", issueTypeValueFound);
      const subjectValueFound = subjectDropdownOptions[
        issueTypeValueFound
      ].find(
        (s) =>
          s.value.replace(/[^a-zA-Z ]/g, "").toLowerCase() ==
          convertUrlStringToStringWithSpaces(subjectArg),
      );

      if (subjectValueFound) {
        setFormValue("subject", subjectValueFound.value);
      }
    } else {
      setFormValue("issueType", "");
    }
  };

  const sendHelpMessage = async (
    msg: { [key: string]: string },
    recaptchaToken?: string | null,
  ) => {
    await sendHelpMessageAPI(msg, recaptchaToken);
  };

  const setNameAndEmailForMembers = (
    account?: AccountWithLoyaltyAndPayment,
  ) => {
    if (account && (account.firstName || account.lastName)) {
      setFormValue("name", `${account.firstName} ${account.lastName}`);
    }

    if (account?.email) {
      setFormValue("email", account.email);
    }
  };

  return (
    <HelpCenterContext.Provider
      value={{
        faqs,
        getFAQs,
        isLoading,
        formState,
        setFormValue,
        sendHelpMessage,
        setInitialIssueTypeAndSubjectFromQueryString,
        clearForm,
        searchFAQ,
        setNameAndEmailForMembers,
      }}
    >
      {children}
    </HelpCenterContext.Provider>
  );
};

export const mapArticleUrls = (faqs: FAQCategories) => {
  const result: FAQCategories = {};
  const sortedArr = Object.entries(faqs).sort(
    (a, b) => a[1][0].priority - b[1][0].priority,
  );
  sortedArr.forEach((k) => {
    const key = k[0];
    const articles = faqs[key];
    result[key] = articles.map((a) => ({
      ...a,
      url: `/help-center/${convertToUrl(key)}/${convertToUrl(a.label)}`,
    }));
  });

  return result;
};

export { HelpCenterProvider, HelpCenterContext };
