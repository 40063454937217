import React, { useState, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { setOverlay } from "State/ui/creators";
import { getCostPerBook } from "Utils/";

import styled from "styled-components";
import { join, updateAccount } from "State/account/flows";
import { Button } from "UI/elements/form/Button";
import { Error } from "UI/elements/form/Error";
import { countryPlanPrice, CountryCode } from "Utils/countryPricing";
import { RegionContext } from "UI/contexts/RegionContext";
import { _SELECT_NUMBERS, getPlanType } from "Utils/index";

const UpgradeMembershipConfModal = (props) => {
  const [showingScreen, setShowingScreen] = useState("conf");
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const { locationHeader } = useContext(RegionContext);

  const changePlan = async () => {
    const yearlyPlan = overlay?.data?.plan;
    if (yearlyPlan) {
      setIsPending(true);
      try {
        await props.join({
          plan: yearlyPlan,
          renewalPlan: yearlyPlan,
          planUpgrade: true,
        });
        setIsPending(false);
        setShowingScreen("success");
      } catch (e) {
        console.log("error", e);
        setError(e);
        setIsPending(false);
      }
    }
  };

  const closeModal = () => {
    const nextStep = props.overlay?.data.nextStep || 2;
    props.setOverlay("");
    props.push("/post-enroll/survey/step-flow/" + nextStep);
  };

  let { overlay } = props;
  if (!overlay || (overlay && overlay.name !== "upgradeMembershipConf")) {
    return null;
  }
  const { cycles, credits } = overlay.data.plan || {};
  const countryCode = props.account.countryCode || locationHeader.countryHeader;
  const priceToDisplay = countryPlanPrice(
    overlay?.data?.plan || {},
    countryCode || CountryCode.USA,
  );
  const successDek = (planType) => {
    switch (planType) {
      case "2 credit":
        return `By continuing, you’ll be charged $${priceToDisplay} per month ($${getCostPerBook(
          priceToDisplay,
          credits,
          countryCode,
        )}/credit) to use on hardcover or audiobooks.`;
      case "Monthly":
      case "Yearly":
        return `By continuing, you’ll be charged $${priceToDisplay} for twelve book credits ($${getCostPerBook(
          priceToDisplay,
          credits,
        )}/credit).`;
      default:
        `By continuing, you’ll be charged $${priceToDisplay} per month ($${getCostPerBook(
          priceToDisplay,
          credits,
          countryCode,
        )}/credit).`;
    }
  };

  const header =
      showingScreen == "success" ? "Success!" : "Upgrade your membership?",
    dek =
      showingScreen == "success"
        ? `Woo hoo! Your membership has been upgraded. You have ${credits} shiny new credits to spend on books each month.`
        : successDek(getPlanType(overlay?.data?.plan));
  return (
    <StyledUpgradeConf>
      {error && <Error message={error.error} />}
      <h4>{header}</h4>
      <p>{dek}</p>
      {showingScreen == "success" && (
        <Button
          style={"fullWidth"}
          handleClick={closeModal}
          title={"Continue"}
          isPending={isPending}
          top={20}
        />
      )}
      {showingScreen != "success" && (
        <>
          <Button
            style={"fullWidth"}
            handleClick={changePlan}
            title={"Confirm"}
            isPending={isPending}
            top={20}
          />
          <a
            onClick={() => props.setOverlay("")}
            style={{
              textAlign: "center",
              display: "block",
              fontSize: "1.8rem",
              marginTop: "20px",
            }}
          >
            No thanks
          </a>
        </>
      )}
    </StyledUpgradeConf>
  );
};

export const StyledUpgradeConf = styled.div`
  text-align: center;
  img {
    height: 80px;
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setOverlay, push, join, updateAccount },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeMembershipConfModal);
