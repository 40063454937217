const { createHash } = require("crypto");

const hashString = (value) => {
  return (0, createHash)("sha256").update(value).digest("hex");
};

const pageView = (account = null, location) => {
  try {
    dataLayerEventTracking("pageview", {
      userId: account?.id,
      email: account?.email,
      pageview_email_hashed: hashString(account?.email?.toLowerCase().trim()),
      logged_in: !!account?.id,
      url: location.replace(/\?.*?$/, ""),
    });
  } catch (e) {
    console.error("TCL: pageView -> e", e);
  }
};

const enrollConfirmation = ({
  enrollmentType,
  email,
  firstName,
  lastName,
  planName,
  orderId,
  orderTotal,
}) => {
  try {
    dataLayerEventTracking(enrollmentType, {
      enroll_email: email,
      enroll_email_hashed: hashString(email?.toLowerCase().trim()),
      firstName: firstName,
      lastName: lastName,
      plan: planName,
      orderID: orderId,
      orderid_quant: orderId,
      orderTotalCents: Math.ceil(orderTotal * 100),
      orderTotalDollars: orderTotal.toFixed(2),
    });
  } catch (e) {
    console.error("TCL: enrollConfirmation -> e", e);
  }
};

const ecommerceTracking = (type, order, plan, promo) => {
  try {
    let coupon = promo?.code || "",
      tag = "order-",
      orderID = order.id,
      orderTotal = order.lines.reduce((sum, line) => (sum += line.amount), 0),
      taxLine = order.lines.find((line) => line.resourceType === "Tax"),
      tax = taxLine ? taxLine["amount"] : 0,
      currency = taxLine ? taxLine["currencyCode"] : "USD";
    if (type === "gift-purchase") {
      tag = "gift-";
    } else if (type === "gift-redeem") {
      tag = "gift-redeem-";
      orderTotal = 0;
      tax = 0;
    }
    dataLayerTracking({
      ecommerce: {
        purchase: {
          actionField: {
            id: tag + orderID,
            revenue: orderTotal.toFixed(2),
            tax: tax.toFixed(2),
            shipping: "0.00",
            coupon,
          },
          products: [
            {
              name: plan.name,
              id: plan.id.toString(),
              price: plan.price.toString(),
              category: type === "gift-purchase" ? "gift" : "membership",
              quantity: 1,
            },
          ],
        },
      },
    });
    if (type !== "gift-purchase") {
      dataLayerTracking({
        orderIdpp: orderID,
        orderValue: orderTotal.toFixed(2),
        currency: currency,
      });
    }
  } catch (e) {
    console.error("TCL: ecommerceTracking -> e", e);
  }
};

const leadCapture = ({ id, email }) => {
  try {
    dataLayerEventTracking("flowlead", {
      email: email,
      userId: id,
    });
    dataLayerTracking({
      quant_lead: id,
    });
  } catch (e) {
    console.error("TCL: leadCapture -> e", e);
  }
};

const experimentFlowTracking = (eventAction, eventLabel = undefined) => {
  try {
    dataLayerEventTracking("enrollFlowExperiment", {
      eventCategory: "Enroll Flow",
      eventAction,
      eventLabel,
    });
  } catch (e) {
    console.error("TCL: experimentFlowTracking -> e", e);
  }
};
const cancelFlowTracking = (
  eventCategory,
  eventAction = "",
  eventLabel = "",
) => {
  try {
    dataLayerEventTracking("cancelFlow", {
      eventCategory: "Enroll Flow",
      eventAction,
      eventLabel,
    });
  } catch (e) {
    console.error("TCL: cancelFlowTracking -> e", e);
  }
};
const giftConfirmationTracking = (planId, price, currencyCode) => {
  try {
    dataLayerEventTracking("giftpurchase", undefined, {
      plan: planId,
      price: price,
      currency: currencyCode,
    });
  } catch (e) {
    console.error("TCL: giftConfirmationTracking -> e", e);
  }
};

const planSelectTracking = (type, planId) => {
  try {
    dataLayerEventTracking(
      type === "gift" ? "giftSelectRenewalPlan" : "selectPlan",
      undefined,
      { plan: planId },
    );
  } catch (e) {
    console.error("TCL: planSelectTracking -> e", e);
  }
};

const dataLayerTracking = (trackingObj = {}) => {
  try {
    window?.dataLayer?.push(trackingObj);
  } catch (e) {
    console.error("TCL: dataLayerTracking -> e", e);
  }
};

const dataLayerEventTracking = (event, trackingObj = {}, variables = {}) => {
  /**
   *  trackingObj:
   *    'eventCategory'
   *    'eventAction'
   *    'eventLabel'
   */
  try {
    window?.dataLayer?.push({
      event,
      ...trackingObj,
      ...variables,
    });
  } catch (e) {
    console.error("TCL: dataLayerEventTracking -> e", e);
  }
};

export {
  pageView,
  enrollConfirmation,
  ecommerceTracking,
  leadCapture,
  experimentFlowTracking,
  cancelFlowTracking,
  planSelectTracking,
  giftConfirmationTracking,
  dataLayerEventTracking,
  dataLayerTracking,
};
