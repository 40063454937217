/**
 * Nav links for mobile devices.
 * Replaces member and non member navs on mobile devices.
 * Child of BOM.jsx
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStoreData } from "UI/hoc/withStoreData";
import { logout } from "State/account/flows";
import { experimentFlowTracking } from "Utils/analytics";
import { push } from "connected-react-router";
import ClickTracker from "UI/elements/ClickTracker";
import { setOverlay } from "State/ui/creators";
import {
  MobileNavSecondaryBlock,
  StyledMobileNavSecondaryBlockWrapper,
} from "UI/elements/navigation/MobileNavSecondaryBlock";
import { isReferred } from "State/joinData/selectors";
import { snesRedirect } from "../../utils/snesRedirect";
import { Link as SCLink } from "../../ui/elements/navigation/Link";

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      navTop: 0,
      bannersHeight: 0,
    };
    this.openExpander = this.openExpander.bind(this);
    this.closeExpander = this.closeExpander.bind(this);
    this.setNavHeight = this.setNavHeight.bind(this);
    this.openSignupModal = this.openSignupModal.bind(this);
    this.assembleNavMenu = this.assembleNavMenu.bind(this);
    this.signupAction = this.signupAction.bind(this);
    this.toggleReferActive = this.toggleReferActive.bind(this);
    this.navigate = this.navigate.bind(this);
    this.swagShopAction = this.swagShopAction.bind(this);
  }

  async componentDidMount() {
    this.setNavHeight();
    if (window) {
      window.addEventListener("resize", this.setNavHeight);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.path !== this.props.path) {
      this.setNavHeight();
    } else {
      this.setNavHeight(prevState);
    }
    this.toggleReferActive();
  }

  toggleReferActive() {
    let referLink = document.getElementById("mobile-refer");
    if (
      this.props.path == "/my-account/refer-a-friend" ||
      this.props.path == "/refer-a-friend"
    ) {
      referLink?.classList?.add?.("active");
    } else {
      referLink?.classList?.remove?.("active");
    }
  }

  componentWillUnmount() {
    document.removeEventListener("resize", this.setNavHeight);
  }

  signupAction() {
    this.props.setHideMobileNav();
    if (this.props.isSoftLoggedIn) {
      this.props.push("/join-billing");
    } else {
      this.openSignupModal();
    }
  }

  swagShopAction() {
    this.props.setHideMobileNav();
  }

  logOut() {
    this.props.logout();
    if (this.props.setHideMobileNav) {
      this.props.setHideMobileNav();
    }
    return false;
  }

  setNavHeight(prevState = null) {
    let D = document;
    if (D) {
      if (D.getElementById("mobileNavScroller")) {
        D.getElementById("mobileNavScroller").style.height =
          window.innerHeight - 50 + "px";
      }

      let bannersHeight = D.getElementById("banners")
          ? D.getElementById("banners").offsetHeight
          : 0,
        navTop = this.props.isRejoinF ? 75 : bannersHeight == 0 ? 0 : 50;
      if (!prevState || prevState.bannersHeight != bannersHeight) {
        this.setState({
          bannersHeight: bannersHeight,
          navTop: navTop,
        });
      }
    }
  }

  openExpander() {
    this.setState({ isExpanded: true });
  }

  closeExpander() {
    this.setState({ isExpanded: false });
  }

  gaTracking(eventAction) {
    experimentFlowTracking(eventAction);
  }

  openSignupModal() {
    if (this.props.entryPoint !== "leadEmail") {
      this.props.setOverlay("signUpModal");
    } else {
      this.props.push("/step-join");
    }
  }

  assembleNavMenu() {
    let {
        storeData,
        setHideMobileNav,
        isReallyLoggedIn,
        isSoftLoggedIn,
        isGift,
        renewalPlan,
        appFeatures,
        isCanadaMode,
        isFAQsActive,
      } = this.props,
      { track, currentCycle } = storeData;
    let accountType = isReallyLoggedIn
      ? "Member"
      : isSoftLoggedIn
      ? "Lead"
      : "Visitor";
    let showGiftRenewal = Boolean(isGift && !renewalPlan);

    const aboutUsAppFeature = appFeatures?.find(
      (f) => f?.feature == "About Us Page",
    );

    const isVBTActive =
      appFeatures?.find((f) => f?.feature == "Virtual book tour")?.status ==
      "Active";
    let miniNavHeader =
      this.props.isSwagShopActive && this.props.account !== null ? (
        <>SHOPPING</>
      ) : (
        <>PICK A BOOK</>
      );
    const audioBooksFeature = appFeatures?.find(
      (f) => f?.feature == "Audio Books",
    );
    const showMyPlan =
      audioBooksFeature?.status == "Active" ||
      (audioBooksFeature?.status == "Beta" &&
        audioBooksFeature?.isBetaEligible);

    let pickaBookMenu = (
      <ul key={"pickaBook"}>
        <li className="miniText">{miniNavHeader}</li>
        <li>
          <a
            onClick={() => snesRedirect("snes", "/the-best-new-books")}
            tabIndex="0"
          >
            {`${currentCycle?.label || "This month’s"} books`}
          </a>
        </li>
        <li>
          <a
            onClick={() => snesRedirect("snes", "/all-hardcovers")}
            tabIndex="0"
          >
            All books
          </a>
        </li>
        {accountType == "Member" && (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              tabIndex="0"
              to="/to-be-read"
              handleClick={setHideMobileNav}
              logClickData={"Clicked-hamburger-nav-tbr"}
              title={"To be read"}
            />
          </li>
        )}
        {/* @TODO: Temp hides BOTY */}
        <li>
          <a
            onClick={() => snesRedirect("snes", "/best-books-of-the-year")}
            tabIndex="0"
          >
            Book of the Year
          </a>
        </li>
      </ul>
    );

    let aboutBOTMMenu = (
      <ul key={"aboutBOTM"}>
        <li className="miniText">ABOUT BOTM</li>
        {isReallyLoggedIn && (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              tabIndex="0"
              to="/help-center"
              handleClick={setHideMobileNav}
              title={"Help center"}
              logClickData={"Clicked-hamburger-FAQs"}
            />
          </li>
        )}
        <a onClick={() => snesRedirect("snes", "/about-us")} tabIndex="0">
          About us
        </a>
        {isVBTActive && (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              tabIndex="0"
              handleClick={setHideMobileNav}
              to={"/vbt"}
              title={"Virtual Book Tour"}
              logClickData={"Clicked-hamburger-nav-vbt"}
            />
          </li>
        )}
        <li>
          <ClickTracker
            ctaType={"navLink"}
            tabIndex="0"
            handleClick={setHideMobileNav}
            to="/relationship-status"
            title={"Relationship Status"}
            logClickData={"Clicked-hamburger-nav-relationship-status"}
          />
        </li>
        {(isReallyLoggedIn || isSoftLoggedIn) && (
          <li>
            <a href="https://www.volume0.com/">VOLUME Ø</a>
          </li>
        )}
        <li>
          <a href="https://wwd.com/pop-culture/culture-news/book-of-the-month-kaliane-bradley-ministry-of-time-brianna-goodman-1236412340/">
            Recent Press
          </a>
        </li>
        {accountType == "Member" && (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              to="/careers"
              title={"Careers"}
              logClickData={"Clicked-hamburger-nav-careers"}
            />
          </li>
        )}
      </ul>
    );
    let giftsMenu = (
      <ul key={"giftsMenu"}>
        <li className="miniText">GIFTS</li>
        <li>
          <a onClick={() => snesRedirect("snes", "/gift")} tabIndex="0">
            Give a gift
          </a>
        </li>
        {
          <li>
            <ClickTracker
              ctaType={"navLink"}
              to="/gift/redeem"
              handleClick={setHideMobileNav}
              title={"Redeem a gift"}
              logClickData={"Clicked-hamburger-nav-redeem-gift"}
            />
          </li>
        }
        {track.id === 1 && !isCanadaMode ? (
          <li>
            <a onClick={() => snesRedirect("snes", "/corporate")} tabIndex="0">
              Group gifting
            </a>
          </li>
        ) : null}
      </ul>
    );
    let getInTouchMenu = (
      <ul key={"getintouch"}>
        <li className="miniText">GET IN TOUCH</li>
        <li>
          <ClickTracker
            ctaType={"navLink"}
            to="/help-center"
            handleClick={setHideMobileNav}
            title={"Help center"}
            logClickData={"Clicked-hamburger-nav-help-center"}
          />
        </li>
        <li>
          <ClickTracker
            ctaType={"navLink"}
            to="/careers"
            handleClick={setHideMobileNav}
            title={"Careers"}
            logClickData={"Clicked-hamburger-nav-careers"}
          />
        </li>
      </ul>
    );
    let yourMembersMenu = (
      <ul key={"membership"}>
        <li className="miniText">MY MEMBERSHIP</li>
        <li>
          <ClickTracker
            ctaType={"navLink"}
            to="/my-account/account"
            handleClick={setHideMobileNav}
            title={"Account details"}
            logClickData={"Clicked-hamburger-nav-account-details"}
          />
        </li>
        <li>
          <ClickTracker
            ctaType={"navLink"}
            to="/my-account/orders"
            handleClick={setHideMobileNav}
            title={"My orders"}
            logClickData={"Clicked-hamburger-nav-my-orders"}
          />
        </li>
        {showMyPlan && (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              to="/my-account/membership"
              handleClick={setHideMobileNav}
              title={"My plan"}
              logClickData={"Clicked-hamburger-nav-my-plan"}
            />
          </li>
        )}
        {isReallyLoggedIn ? (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              to="/refer-a-friend"
              handleClick={setHideMobileNav}
              title={"Refer a friend"}
              logClickData={"Clicked-hamburger-nav-refer-a-frien"}
            />
          </li>
        ) : null}
        {isReallyLoggedIn ? (
          <li>
            <ClickTracker
              ctaType={"navLink"}
              to="/bookshelf"
              handleClick={setHideMobileNav}
              title={"My bookshelf"}
              logClickData={"Clicked-hamburger-nav-my-bookshelf"}
            />
          </li>
        ) : null}
      </ul>
    );

    let logoutMenu = (
      <ul key={"logout"}>
        <li>
          <a href="#" onClick={this.logOut.bind(this)}>
            Log out
          </a>
        </li>
      </ul>
    );

    switch (accountType) {
      case "Member":
        return showGiftRenewal
          ? [
              pickaBookMenu,
              yourMembersMenu,
              giftsMenu,
              aboutBOTMMenu,
              logoutMenu,
            ]
          : [pickaBookMenu, yourMembersMenu, giftsMenu, aboutBOTMMenu];
      case "Lead":
        return [
          pickaBookMenu,
          aboutBOTMMenu,
          giftsMenu,
          getInTouchMenu,
          logoutMenu,
        ];
      case "Visitor":
      default:
        return [pickaBookMenu, aboutBOTMMenu, giftsMenu, getInTouchMenu];
    }
  }

  navigate(path) {
    this.props.push(path);
  }

  render() {
    let {
      isReallyLoggedIn,
      isSoftLoggedIn,
      overlay,
      isRejoin,
      isGift,
      renewalPlan,
      account,
    } = this.props;
    let accountType = isReallyLoggedIn
      ? "Member"
      : isSoftLoggedIn
      ? "Lead"
      : "Visitor";
    let showGiftRenewal = Boolean(isGift && !renewalPlan);
    let isCCFail =
      account?.policy?.type === "Rejoin" &&
      account?.policy.subType == "CC Fail";
    const isRenewingGiftMember =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      account?.renewalPlan &&
      !account?.paymentMethod;

    let authAction =
      accountType !== "Member" ? (
        <StyledMobileNavSecondaryBlockWrapper accountType={accountType}>
          <MobileNavSecondaryBlock>
            <ClickTracker
              ctaType={"buttonLink"}
              id="mobile-nav-signup-link"
              style={"primary"}
              handleClick={() => this.signupAction()}
              title={"Sign Up"}
              logClickData={"Clicked - mobile-header-join-now"}
            />

            {accountType == "Visitor" && (
              <span className="miniText">
                Already a member?{" "}
                <SCLink
                  id="mobile-nav-signin-link"
                  linkTo={"/login"}
                  navigate={() => snesRedirect("snes", "/login")}
                  size={14}
                >
                  Sign in.
                </SCLink>
              </span>
            )}
          </MobileNavSecondaryBlock>
        </StyledMobileNavSecondaryBlockWrapper>
      ) : isRejoin ? (
        <StyledMobileNavSecondaryBlockWrapper>
          <MobileNavSecondaryBlock>
            <div>
              {isCCFail ? (
                <ClickTracker
                  ctaType={"buttonLink"}
                  id="mobile-nav-update-card-link"
                  style={"primary"}
                  handleClick={() => this.props.setOverlay("ShipBillingModal")}
                  title={"Update your card"}
                  logClickData={"Clicked - mobile-header-update-card"}
                />
              ) : (
                <ClickTracker
                  ctaType={"buttonLink"}
                  id="mobile-nav-rejoin-link"
                  linkTo={"/credit-rejoin/step-join"}
                  title={"Rejoin"}
                  logClickData={"Clicked - mobile-header-rejoin"}
                  style={"primary"}
                />
              )}
            </div>
            <span className="miniText">
              <Link className="link miniText" onClick={this.logOut.bind(this)}>
                Log out
              </Link>
            </span>
          </MobileNavSecondaryBlock>
        </StyledMobileNavSecondaryBlockWrapper>
      ) : showGiftRenewal ? (
        <StyledMobileNavSecondaryBlockWrapper>
          <MobileNavSecondaryBlock>
            <div>
              <ClickTracker
                ctaType={"buttonLink"}
                id="mobile-nav-renewal-link"
                style={"primary"}
                handleClick={() =>
                  snesRedirect("snes", "/my-account/gift-offer")
                }
                title={"Choose a renewal plan"}
                logClickData={"Clicked - renew_mobilenavweb"}
              />
            </div>
          </MobileNavSecondaryBlock>
        </StyledMobileNavSecondaryBlockWrapper>
      ) : isRenewingGiftMember ? (
        <StyledMobileNavSecondaryBlockWrapper>
          <MobileNavSecondaryBlock>
            <div>
              <ClickTracker
                ctaType={"buttonLink"}
                id="mobile-nav-logout-link"
                style={"secondary"}
                handleClick={this.logOut.bind(this)}
                title={"Log Out"}
                logClickData={"Clicked - Log Out - mobile nav "}
              />
            </div>
          </MobileNavSecondaryBlock>
        </StyledMobileNavSecondaryBlockWrapper>
      ) : (
        <StyledMobileNavSecondaryBlockWrapper>
          <MobileNavSecondaryBlock>
            <div>
              <ClickTracker
                ctaType={"buttonLink"}
                id="mobile-nav-logout-link"
                style={"primary"}
                handleClick={this.logOut.bind(this)}
                title={"Log Out"}
                logClickData={"Clicked - Log Out - mobile nav "}
              />
            </div>
          </MobileNavSecondaryBlock>
        </StyledMobileNavSecondaryBlockWrapper>
      );

    return (
      <div
        className={
          "mobileNav" + (overlay && overlay.name == "mobileNav" ? " open" : "")
        }
        style={{ top: this.state.navTop }}
      >
        <div
          className={
            "mobileNavScroller" + (isReallyLoggedIn ? " loggedIn" : "")
          }
          id="mobileNavScroller"
        >
          {isRenewingGiftMember && (
            <StyledMobileNavSecondaryBlockWrapper>
              <ClickTracker
                ctaType={"buttonLink"}
                id="mobile-nav-update-billing-link"
                style={"primary -fullWidth"}
                handleClick={() =>
                  this.props.push("/gift/preenroll").bind(this)
                }
                title={"Update billing info"}
                logClickData={"Clicked - mobile-nav-add-billing-info"}
              />
            </StyledMobileNavSecondaryBlockWrapper>
          )}
          {this.assembleNavMenu()}
        </div>
        {authAction}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { appFeatures } = state;
  const swagAppFeature = appFeatures?.find((f) => f?.feature == "Swag shop");
  const isSwagShopActive = swagAppFeature?.status == "Active";
  const isFAQsActive =
    appFeatures?.find((f) => f?.feature == "FAQs").status == "Active";

  return {
    store: state,
    account: state.account,
    isLoggedIn: state.policy.type !== "Visitor",
    isSoftLoggedIn: ["Lead", "User"].includes(state.policy.type),
    isReallyLoggedIn:
      (state.account &&
        !["Visitor", "Lead", "User"].includes(state.policy.type)) ||
      false,
    first_name: state.account ? state.account.firstName : "",
    isRejoinF: state.policy.subType == "Rejoin F",
    isGift:
      state.account?.policy?.type == "Member" &&
      state.account?.policy?.subType == "Gift",
    renewalPlan: state.account?.renewalPlan,
    overlay: state.ui ? state.ui.overlay : null,
    path: state.analytics.location ? state.analytics.location : null,
    isRejoin: state.policy.type === "Rejoin",
    entryPoint: state.entryPoint,
    isSwagShopActive,
    isFAQsActive,
    isReferral: isReferred(state),
    isExcludedFromExperiments: state.entryPoint == "getstarted",
    appFeatures: state.appFeatures,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout, setOverlay, push }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
    MobileNav,
  ),
);
