/**
 * FriendInviteLink.jsx
 * Form for inviting friends using Link
 * Child of ReferFriendForm.jsx
 * */
import React, { Component } from "react";
import ClickTracker from "UI/elements/ClickTracker";
import { COLORS } from "CSS/Consts.js";
import styled from "styled-components";
class FriendInviteLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  async copyAction() {
    let copyTextarea = document.querySelector(".js-copytextarea");
    copyTextarea.select();
    try {
      var successful = document.execCommand("copy");
      if (successful) {
        this.setState({ copied: true }, () =>
          setTimeout(() => {
            this.setState({ copied: false });
            copyTextarea.blur();
          }, 2000),
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    let { referralLink, location, entryPoint } = this.props;
    let buttonText = this.state.copied ? "Copied!" : "Copy";

    return (
      <StyledCopyWrapper>
        <StyledInput
          id="refLink"
          className="js-copytextarea fullWidth -noSelectHighlight"
          type="text"
          required
          value={referralLink}
          placeholder=""
          readOnly
          contentEditable={true}
        />
        <ClickTracker
          ctaType={"button"}
          id="raf-invite-link"
          style={"primary"}
          customClass={"link-p1 doNotStyle"}
          handleClick={() => this.copyAction(referralLink)}
          title={buttonText}
          logClickData={"raf-link-" + location + "-" + entryPoint}
        />
      </StyledCopyWrapper>
    );
  }
}
const StyledCopyWrapper = styled.div`
  display: flex;
`;
const StyledInput = styled.input`
  padding: 16px;
`;

export default FriendInviteLink;
