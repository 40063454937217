import globalScope from "../../global"; // For tests, ugh

export async function loadIndex() {
  const response = await fetch(`${global.apiHost}/api/v0/search/index`);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const searchIndex = await response.json();
  return searchIndex;
}

export async function query(searchTerm: string, onlyCanada: boolean = false) {
  const response = await fetch(
    `${global.apiHost}/api/search/query?s=${searchTerm}&onlyCanada=${onlyCanada}&formatFilter=hardcovers`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const searchResults = await response.json();
  return searchResults;
}
