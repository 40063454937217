/**
 * Main app header.
 * Child of BOM.jsx
 * Children include:
 * logo, hamburger menu, nav and cancelling and card fail banners.
 **/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getScrollPosition } from "Utils/ui";
import { isACRejoin } from "State/account/selectors";
import Burger from "./Burger.jsx";
import Logo from "UI/elements/Logo.jsx";
import Nav from "./Nav.jsx";
import Dashboard from "./Dashboard.jsx";
import SiteResponse from "./SiteResponse.jsx";
import { COLORS } from "CSS/Consts.js";
import { useStoreData } from "UI/hooks/useStoreData.js";

const Header = (props) => {
  const {
    isMember,
    showMobileNav,
    path,
    siteResponse,
    account,
    width,
    isCanadaMode,
    policy,
    location,
  } = props;
  const { store } = useStoreData();
  const { pathname } = location || {};
  const [isFixed, setIsFixed] = useState(false);
  const isMobile = width <= 680;

  const isLoggedIn = !(policy && policy.type)
    ? false
    : policy.type !== "Visitor";

  const isDarkMode =
    (!isLoggedIn && pathname == "/login") ||
    (!isLoggedIn && pathname == "/forgot-password") ||
    store?.maintenance ||
    store?.emergency ||
    pathname.includes("/credit-rejoin") ||
    pathname.includes("/gift") ||
    (pathname.includes("/post-enroll") && !isMobile) ||
    pathname.includes("/vbt") ||
    pathname.includes("/help-center") ||
    pathname.includes("/join-our-team") ||
    pathname.includes("/to-be-read") ||
    pathname.includes("/privacy-policy") ||
    pathname.includes("/relationship-status") ||
    pathname.includes("/my-account") ||
    pathname.includes("/refer-a-friend") ||
    pathname.includes("/gift") ||
    pathname.includes("/privacy-policy") ||
    pathname.includes("/terms-of-membership") ||
    pathname.includes("/pause-membership") ||
    pathname.includes("/cancel") ||
    pathname.includes("/bookshelf") ||
    pathname.includes("/rejoin-confirmation") ||
    pathname.includes("/accessibility-statement") ||
    account?.policy?.subType == "Under Age";

  const logo = (
    <Link to={"/"} className="logoWrapper" tabIndex="0">
      <Logo color={isDarkMode ? COLORS.offwhite1 : COLORS.black} />
    </Link>
  );

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    document.addEventListener("touchmove", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
      document.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let D = document,
      bannersHeight = D?.getElementById("banners")?.offsetHeight;
    let scrollPosition = getScrollPosition();
    if (bannersHeight == 0 || scrollPosition >= bannersHeight) {
      setIsFixed(true);
    } else if (scrollPosition < bannersHeight) {
      setIsFixed(false);
    }
  };

  const dashboard = (
    <Dashboard
      {...props}
      setHideMobileNav={props.setHideMobileNav}
      isDarkMode={isDarkMode}
    />
  );

  const isCourtesy =
    props?.location?.pathname === "/courtesy" &&
    (!props?.account || props?.account?.policy?.type === "Lead");

  return (
    <div
      className={`mainSiteNav ${isFixed ? " fixed" : ""} ${
        isDarkMode ? "darkMode" : ""
      }`}
    >
      {!isCourtesy ? (
        <header
          id="header"
          className={
            "header " +
            (isFixed ? " fixed" : "") +
            (!isMember ? " loggedOut" : "")
          }
        >
          <div
            className="content -innerWrapper"
            style={{ zIndex: 1000, height: "100%" }}
          >
            <Burger
              showMobileNav={showMobileNav}
              setHideMobileNav={props.setHideMobileNav}
              setShowMobileNav={props.setShowMobileNav}
              path={path}
              isLanding={props.isLanding}
              isCanadaMode={isCanadaMode}
              isDarkMode={isDarkMode}
            />
            {logo}
            <div className="navAndActionsContainer">
              <Nav isCanadaMode={isCanadaMode} />
              {dashboard}
            </div>
          </div>
          <SiteResponse siteResponse={siteResponse} />
        </header>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isMember:
      state.loading.loginAttempted &&
      state.policy &&
      (state.policy.type === "Member" ||
        state.policy.type === "Rejoin" ||
        state.policy.type === "Ban"),
    policy: state.account ? state.account.policy : state.policy,
    path: state.analytics.location ? state.analytics.location : null,
    isACRejoin: isACRejoin(state),
    siteResponse: state.ui ? state.ui.siteResponse : null,
    isBluebox: state.promo.bluebox,
    entryPoint: state.entryPoint,
    account: state.account,
    appFeatures: state.appFeatures,
  };
}
export default connect(mapStateToProps)(withRouter(Header));
