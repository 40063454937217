import * as appFeatureApi from "./api";

export const deleteBetaByFeature =
  (appFeatureId) => async (dispatch, getState) => {
    try {
      await appFeatureApi.deleteBetaByFeature(appFeatureId);
    } catch (e) {
      console.error("Error saving removing beta feature: ", e);
    }
  };
