import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "./creators";
import * as actionFlows from "./flows";

const useAccountState = () => {
  const account = useSelector((state: any) => state?.account);
  const dispatch = useDispatch();

  const actions = bindActionCreators(actionCreators, dispatch);
  const flows = bindActionCreators(actionFlows, dispatch);

  return { account, ...actions, ...flows };
};

export default useAccountState;
