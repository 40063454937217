/**
 * FriendInviteEmail.jsx
 * Form for inviting friends using Email
 * Child of ReferFriendForm.jsx
 * */

import React, { Component } from "react";
import ClickTracker from "UI/elements/ClickTracker";
import ModalClose from "UI/elements/ModalClose.jsx";
import DynamicInput from "UI/elements/form/DynamicInput";
import DynamicTextarea from "UI/elements/form/DynamicTextarea";
import Form, { validation } from "UI/components/forms/Form.jsx";
import EmailTemplate from "./RafEmail.html";
import RecommendEmail from "./RecommendEmail.jsx";
import styled from "styled-components";
import { StyledCardBlock } from "UI/containers/CardBlock";
import PointerLeft from "UI/elements/PointerLeft.tsx";

import * as accountApi from "../../../state/account/api";
export default class FriendInviteEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPending: false,
      isCreditMember: props.isCreditMember,
      email: "",
      message: "",
      error: null,
      preview: null,
      sent: false,
      showBox: props.showBox,
      recommendedBook: props.recommendedBook,
      activeField: "",
      invalidEmails: false,
    };
    this.inviteFriend = this.inviteFriend.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
    this.preview = this.preview.bind(this);
    validation["emails"] =
      /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/i;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showBox !== this.props.showBox) {
      this.setState({
        showBox: this.props.showBox,
      });
    }
  }

  setEmails(v) {
    this.setState({
      isPending: false,
      error: null,
      isValid: true,
      email: v,
      invalidEmails: false,
    });
  }

  setMessage(v) {
    this.setState({
      isPending: false,
      error: null,
      isValid: true,
      message: v,
    });
  }

  clearIt() {
    this.setState({
      email: "",
      invalidEmails: false,
    });
  }

  validateEmails() {
    let isValidEmails = validation.emails.test(this.state.email);
    this.setState({
      invalidEmails: !isValidEmails,
    });
  }

  async inviteFriend() {
    this.setState({
      formSubmitting: true,
      isPending: true,
    });
    if (this.state.isValid) {
      await accountApi.createReferral(this.state);
      this.setState(
        {
          sent: true,
          isPending: false,
        },
        () =>
          setTimeout(() => {
            this.setState({ sent: false, email: "", message: "" });
          }, 2000),
      );
    }
  }

  async preview(evt) {
    evt?.preventDefault?.();
    const preview = EmailTemplate({
      firstName: this.props.account?.firstName,
      lastName: this.props.account?.lastName,
      country: this.props.account?.countryCode,
    });
    this.setState({
      preview,
    });
    this.togglePreview();
  }

  togglePreview() {
    this.props?.scrollUp?.();
    this.props.setShowPreview(!this.props.showPreview);
  }
  render() {
    const { preview, error, email, message, isPending, activeField } =
      this.state;
    const {
      location = "generic",
      entryPoint = "generic",
      showMemberReview,
      memberReview,
      showPreview,
      account,
    } = this.props;
    let buttonText = this.state.sent ? "Sent!" : "Send";
    let charactersLeft = 100 - this.state?.message?.length;

    const action = (
      <ClickTracker
        ctaType={"button"}
        id="raf-invite-email-submit"
        customClass={"link-p1 doNotStyle"}
        style={"primary"}
        handleClick={
          email && !this.state.invalidEmails
            ? this.inviteFriend.bind(this)
            : null
        }
        isPending={isPending}
        title={buttonText}
        isDisabled={!email || this.state.invalidEmails}
        logClickData={"raf-email-" + location + "-" + entryPoint}
      />
    );

    const previewModal =
      location == "recommendRead" ? (
        <StyledRecommendPreview
          showPreview={showPreview}
          id={"StyledRecommendPreview"}
        >
          <StyledHeaderBar>
            <PointerLeft
              handleClick={this.togglePreview}
              pointerPrevActive="true"
            />
            <h5>Email preview</h5>
          </StyledHeaderBar>
          <StyledCardBlock
            top={20}
            bottom={40}
            padding={"40px 20px"}
            mobilePadding={"40px 20px"}
            hasShadow
          >
            <RecommendEmail
              message={message}
              memberReview={memberReview}
              showMemberReview={showMemberReview}
            />
          </StyledCardBlock>
        </StyledRecommendPreview>
      ) : (
        <div className={"modalWrapper " + (showPreview ? " showing" : "")}>
          <div
            className={"modal -gate" + (showPreview ? " show" : "")}
            style={{ overflow: "auto" }}
          >
            <ModalClose onClick={this.togglePreview} />
            <h3>Email preview</h3>
            <p style={{ marginBottom: "20px" }}>
              Here’s your email preview.
              <br />
              Doesn’t it look good?
            </p>
            <div
              className="emailPreview"
              dangerouslySetInnerHTML={{ __html: preview || "<div />" }}
            />
          </div>
        </div>
      );

    return (
      <>
        <Form error={error} name="rafForm">
          <div className="rafFormWrapper">
            <fieldset>
              <DynamicInput
                placeholder={
                  activeField == "emails"
                    ? "ex. joe@email.com, jane@email.com"
                    : "First name"
                }
                label={
                  showPreview
                    ? null
                    : activeField == "emails" || email
                    ? "Email addresses (separate by commas)"
                    : "Email addresses"
                }
                value={email}
                accessibilityLabel={`Email addresses`}
                onChangeText={(emails) => {
                  this.setEmails(emails);
                }}
                onFocus={() => this.setState({ activeField: "emails" })}
                onBlur={() => this.validateEmails()}
                isInFocus={activeField == "emails"}
                clearIt={() => this.clearIt()}
                error={
                  this.state.invalidEmails
                    ? "Please add one or more emails."
                    : null
                }
                // forwardRef={firstNameRef}
                required={"required"}
                name={"emails"}
                inputId={"emails"}
                autoComplete={"emails"}
              />
            </fieldset>
            {action}
          </div>
          {/* {location == 'recommendRead' &&
            <StyledButtonWrapper >
              {action}
            </StyledButtonWrapper>
          } */}
        </Form>
        {previewModal}
      </>
    );
  }
}

export const StyledActionsBlock = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
  }
`;

export const StyledButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  button {
    width: 100%;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

export const StyledRecommendPreview = styled.div`
  transition: right 0.4s ease;
  position: absolute;
  top: ${(props) => (props.showPreview ? 0 : "-1000px")};
  right: ${(props) => (props.showPreview ? 0 : "-1000px")};
  width: 100%;
  padding: 20px 40px;
  background-color: var(--color-white);
  height: 100%;
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`;

export const StyledHeaderBar = styled.div`
  text-align: center;
  margin-bottom: 30px;
  svg {
    width: 14px;
  }
  polygon {
    fill: var(--color-black);
  }
`;
