export async function getTermsOfUse(country: string) {
  const response = await fetch(
    `${global.apiHost}/api/termsOfUse/${country}/terms_of_use`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const terms = await response.json();
  return terms;
}

export async function getPrivacyPolicy(country: string) {
  const response = await fetch(
    `${global.apiHost}/api/termsOfUse/${country}/privacy_policy`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const terms = await response.json();
  return terms;
}

export async function getLegalDocsStatus(country: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/termsOfUse/account/${country}`,
    {
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const docs = await response.json();
  return docs;
}
