import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import Authenticate from "../../../ui/skeleton/Authenticate";
import { useWindowSize } from "../../../ui/hooks";
import { MEDIAQUERIES } from "../../../css/Consts";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { IAccount } from "../../../../../types/account";
import { cancelAccount } from "../../../state/account/flows";
import { Dispatch } from "../../../types";
import { useHistory } from "react-router";
import { snesRedirect } from "../../../utils/snesRedirect";

type CancelWithSaveOfferProps = {
  cancelAccount: (cancelConfirmed?: boolean) => void;
} & CancelWithSaveOfferMappedProps;

const CancelWithSaveOffer: React.FC<CancelWithSaveOfferProps> = ({
  account,
  cancelAccount,
}) => {
  const { push } = useHistory();
  const [isPending, setIsPending] = React.useState(false);
  const [width] = useWindowSize();
  const isMobile = width <= 680;
  const { credits } = account || {};
  const hasCredits = account?.credits && account.credits > 0;

  const cancelMe = async () => {
    setIsPending(true);
    await cancelAccount();
    setIsPending(false);
    snesRedirect("snes", "/the-best-new-books");
  };

  const keepMe = async () => {
    snesRedirect("snes", "/the-best-new-books");
  };

  return (
    <StyledCancelWithSaveOffer>
      <Authenticate>
        <StyledFlexWrapper
          id={`CancelWithSaveOffer`}
          paddingTop={isMobile ? 40 : 80}
          paddingLeft={isMobile ? 20 : 0}
          paddingRight={isMobile ? 20 : 0}
          paddingBottom={isMobile ? 80 : 120}
          maxWidth={552}
          justifyContent="center"
          alignItems="center"
        >
          <div className="iconContainer">
            <img
              src={`//static.bookofthemonth.com/Bookmojis/Very_Challenging_Indicator.svg`}
              className="icon"
            />
          </div>
          <h4 className="header">You still have an offer to use!</h4>
          <div className="copy">
            {`If you cancel you will lose your lower price of $9.99 for next
            month. ${
              hasCredits
                ? `You will also lose your remaining ${credits} credit${
                    credits && credits > 1 ? "s" : ""
                  }!`
                : ""
            }`}
          </div>
          <ClickTracker
            ctaType={"button"}
            id="submit-and-cancel"
            style={`primary ${isMobile ? "-fullWidth" : ""}`}
            handleClick={cancelMe}
            title={"Submit and cancel"}
            logClickData={"credit_commit_offer_cancel_with_save_offer"}
            top={40}
            minWidth={isMobile ? 0 : 392}
            isPending={isPending}
          />
          <ClickTracker
            ctaType={"button"}
            id="keep-my-membership"
            style={`secondary ${isMobile ? "-fullWidth" : ""}`}
            handleClick={keepMe}
            title={"Keep my membership"}
            logClickData={"credit_commit_offer_keep_my_membership"}
            top={20}
            minWidth={isMobile ? 0 : 392}
          />
        </StyledFlexWrapper>
      </Authenticate>
    </StyledCancelWithSaveOffer>
  );
};

export const StyledCancelWithSaveOffer = styled.div`
  .iconContainer {
    width: 100px;
    height: 100px;
  }

  .icon {
    width: 100%;
  }

  .header,
  .copy {
    padding-top: 20px;
    text-align: center;
  }

  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    .iconContainer {
      width: 80px;
      height: 80px;
    }

    .header,
    .copy {
      padding-top: 12px;
    }
  }
`;

type CancelWithSaveOfferMappedProps = {
  account?: IAccount;
};

const mapStateToProps = ({ account }: CancelWithSaveOfferMappedProps) => {
  return {
    account: account,
  };
};

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ cancelAccount }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelWithSaveOffer);
