import PageLayout from "../../../ui/components/structure/PageLayout";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import ClickTracker from "../../../ui/elements/ClickTracker";
import { useWindowSize } from "../../../ui/hooks";
import React from "react";

const ThankYou: React.FC = () => {
  const [width] = useWindowSize();
  const isMobile = width < 680;
  const NORExpId = "NORPauseCancelExp134-ThankYou";
  return (
    <PageLayout isMobile={isMobile}>
      <StyledFlexWrapper maxWidth={isMobile ? 80 : 100}>
        <img
          alt="Thank you"
          src={"//static.bookofthemonth.com/plans/check_green.svg"}
        />
      </StyledFlexWrapper>
      <StyledFlexWrapper
        resetSpacing
        textAlign="center"
        gap={isMobile ? 12 : 20}
        marginTop={isMobile ? 12 : 20}
      >
        <h4>Thank you for your feedback!</h4>
        <p>We hope to see you again soon.</p>
      </StyledFlexWrapper>
      <StyledFlexWrapper maxWidth={isMobile ? undefined : 230} marginTop={40}>
        <ClickTracker
          ctaType={"button"}
          id={`return-to-account-${NORExpId}`}
          style={"primary -fullWidth"}
          linkTo={"/my-account/membership"}
          title={"Return to account"}
          logClickData={`Clicked - return to account - ${NORExpId}`}
        />
      </StyledFlexWrapper>
    </PageLayout>
  );
};

export default ThankYou;
