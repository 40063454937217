import * as actions from "./actions";

export const initialState = {
  plan: null,
  product: false,
  tax_rate: 0.0,
  token: null,
  error: null,
  referralCode: null,
  holidayCode: null,
  email: null,
  promo: null,
  status: null,
  giftCode: null,
  giftRejoin: false,
  freeBook: null,
  grouponCode: null,
  giftRedirect: false,
  planOpenRedirect: false,
  giftPurchaseRedirect: false,
  password: null,
  shipping: null,
  account: null,
  loginViaFB: false,
  skippedStepPlan: false,
  order_id: null,
  promoError: null,
  enrollStep: "genre",
  type: "join",
  rejoinBase: "",
  stepJoinStep: null,
  fauxBox: [null, null, null], //i = 0 is selection, i > 0 is addon
  renewalPlan: null,
  autoPromoCode: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STEP_JOIN_STEP:
      return {
        ...state,
        stepJoinStep: action.payload,
      };
    case actions.ADD_JOIN_GENRE:
      return {
        ...state,
        genres: action.payload,
      };
    case actions.REMOVE_JOIN_GENRE:
      return {
        ...state,
        genres: state.store.genres.filter((g) => g !== action.payload),
      };
    case actions.SET_JOIN_CHILDS_INFO:
      return {
        ...state,
        child: action.payload,
      };
    case actions.SET_JOIN_FREQUENCY:
      return {
        ...state,
        frequency: action.payload,
      };
    case actions.SET_JOIN_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case actions.SET_JOIN_RENEWAL_PLAN:
      return {
        ...state,
        renewalPlan: action.payload,
      };
    case actions.SET_JOIN_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case actions.SET_JOIN_PROMO:
      if (
        typeof window !== "undefined" &&
        window.dataLayer &&
        action.payload != null &&
        action.payload.code != null &&
        (action.payload.code.toLowerCase() === "page5" ||
          action.payload.code.toLowerCase() === "botm5")
      ) {
        window.dataLayer.push({
          event: "usedPromo",
          label: action.payload.code,
        });
      }
      return {
        ...state,
        promo: action.payload,
      };
    case actions.SET_JOIN_TAX_RATE:
      return {
        ...state,
        tax_rate: action.payload,
      };
    case actions.SET_JOIN_CLIENT_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case actions.SET_JOIN_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case actions.SET_GIFT_CODE:
      return {
        ...state,
        giftCode: action.payload,
      };
    case actions.SET_GIFT_COUNTRY_CODE:
      return {
        ...state,
        giftCountryCode: action.payload,
      };
    case actions.SET_JOIN_REFERRAL:
      return {
        ...state,
        referralCode: action.payload,
      };
    case actions.SET_JOIN_HOLIDAY_CODE:
      return {
        ...state,
        holidayCode: action.payload,
      };
    case actions.SET_JOIN_CYBER_MONDAY:
      return {
        ...state,
        cyberMonday: true,
      };
    case actions.SET_GROUPON_CODE:
      return {
        ...state,
        grouponCode: action.payload,
      };
    case actions.SET_JOIN_FREE_BOOK:
      return {
        ...state,
        freeBook: action.payload,
      };
    case actions.CLEAR_JOIN_STATUS:
      return {
        ...state,
        status: null,
      };
    case actions.SET_JOIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actions.SET_NEW_MEMBER:
      return {
        ...state,
        newMember: true,
      };
    case actions.CLEAR_NEW_MEMBER:
      return {
        ...state,
        newMember: false,
      };
    case actions.SET_JOIN_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case actions.SET_PLAN_OPEN_REDIRECT:
      return {
        ...state,
        planOpenRedirect: true,
      };
    case actions.SET_GIFT_REDIRECT:
      return {
        ...state,
        giftRedirect: true,
      };
    case actions.SET_GIFT_PURCHASE_REDIRECT:
      return {
        ...state,
        giftPurchaseRedirect: true,
      };
    case actions.CLEAR_JOIN_ERROR:
      return {
        ...state,
        error: null,
      };
    case actions.CLEAR_JOIN_DATA:
      return { ...initialState };
    case actions.SET_JOIN_SHIPPING:
      return {
        ...state,
        shipping: action.payload,
      };
    case actions.SET_JOIN_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case actions.SET_JOIN_ACCOUNT:
      return {
        ...state,
        account: state.account
          ? { ...state.account, ...action.payload }
          : action.payload,
      };
    case actions.SET_ORDER_ID:
      return {
        ...state,
        order_id: action.payload,
      };
    case actions.SET_JOIN_STEP_SKIPPED_PLAN:
      return {
        ...state,
        skippedStepPlan: true,
      };
    case actions.CLEAR_JOIN_STEP_SKIPPED_PLAN:
      return {
        ...state,
        skippedStepPlan: false,
      };
    case actions.SET_JOIN_PROMO_ERROR:
      return {
        ...state,
        promoError: action.payload,
      };
    case actions.CLEAR_JOIN_PROMO_ERROR:
      return {
        ...state,
        promoError: null,
      };
    case actions.SET_LOGIN_VIA_FB:
      return {
        ...state,
        loginViaFB: true,
      };
    case actions.SET_JOIN_STEP:
      return { ...state, enrollStep: action.payload };
    case actions.SET_JOIN_STEP_DEFAULT:
      return { ...state, enrollStep: actions.JOIN_GENRE };
    case actions.SET_JOIN_TYPE:
      return {
        ...state,
        type: action.payload,
        rejoinBase:
          action.payload === "rejoin"
            ? state.rejoinBase || "credit-rejoin"
            : "",
      };
    case actions.SET_GIFT_OFFER:
      return {
        ...state,
        giftOffer: action.payload,
      };
    case actions.SET_SURVEY_AUTH:
      return {
        ...state,
        surveyAuth: action.payload,
      };
    case actions.SET_GRIFTER_PROMO_CODE:
      return {
        ...state,
        grifterCode: action.payload,
      };
    case actions.SET_REJOIN_BASE_PATH:
      return { ...state, rejoinBase: action.payload };
    case actions.SET_FAUX_BOX:
      return { ...state, fauxBox: action.payload };
    case actions.SET_GIFT_REJOIN:
      return {
        ...state,
        giftRejoin: true,
      };
    case actions.SET_AUTO_PROMO_CODE:
      return {
        ...state,
        autoPromoCode: action.payload,
      };
    default:
      return state;
  }
}
