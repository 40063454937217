import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { ModalEditWrapper } from "UI/containers/ModalEditWrapper";
import ShipBilling from "UI/pages/join/ShipBilling.jsx";
import SuccessRejoin from "UI/pages/join/SuccessRejoin.jsx";
import { Error } from "UI/elements/form/Error";
import { CreditCommitProvider } from "UI/contexts/CreditCommitContext";

const ShipBillingModal = (props) => {
  const [status, setStatus] = useState("billing");
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState("");

  return (
    <CreditCommitProvider>
      <div>
        {status === "billing" ? (
          <ModalEditWrapper
            header={"Update payment method"}
            dek={
              "Your membership failed to renew. Update your payment info for more great reads."
            }
          >
            {error && (
              <Error
                message={
                  "Oops! Please double-check your credit card info and try again."
                }
              />
            )}
            <ShipBilling
              {...props}
              setStatus={setStatus}
              setFormIsValid={setFormIsValid}
            />
          </ModalEditWrapper>
        ) : (
          <SuccessRejoin removeOverlay={props.removeOverlay} />
        )}
      </div>
    </CreditCommitProvider>
  );
};

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipBillingModal);
