export const convertToUrl = (str: string) => {
  if (!str) return "";

  return str
    .replace(/[^a-zA-Z ]/g, "")
    .split(" ")
    .join("-")
    ?.toLowerCase();
};

export const convertUrlStringToStringWithSpaces = (str: string) => {
  if (!str) return "";

  return str.replaceAll("-", " ");
};

export const mappedIssueTypes = {
  CHANGE_ORDER: "Change Order",
  ORDER_AND_SHIPPING_HELP: "Order and Shipping Help",
  CREDITS_AND_CHARGES: "Credits and Charges",
  SUBSCRIPTION_MANAGEMENT: "Subscription Management",
  GIFTS: "Gifts",
  RELATIONSHIP_STATUS: "Relationship Status",
  TECHINCAL_ISSUE: "Technical Issue",
  AUDIOBOOKS: "Audiobooks",
  GIVE_FEEDBACK: "Give Feedback",
  SOMETHING_ELSE: "Something Else",
} as const;

export enum FormAction {
  DEFAULT_FORM = "DEFAULT_FORM",
  BFF_BIRTHDAY = "BFF_BIRTHDAY",
  CHANGE_ADDRESS = "CHANGE_ADDRESS",
  FIND_MY_BOX = "FIND_MY_BOX",
  MARKED_AS_DELIVERED_NOT_RECEIVED = "MARKED_AS_DELIVERED_NOT_RECEIVED",
  ISSUE_WITH_BOOK = "ISSUE_WITH_BOOK_FORM",
  SET_ISSUE_TYPE = "SET_ISSUE_TYPE",
  SET_FIELD_VALUE = "SET_FIELD_VALUE",
  CLEAR_FORM = "CLEAR_FORM",
}
