export const ADD_JOIN_GENRE = "ADD_JOIN_GENRE";
export const REMOVE_JOIN_GENRE = "REMOVE_JOIN_GENRE";
export const SET_JOIN_ACCOUNT = "SET_JOIN_ACCOUNT";
export const SET_JOIN_FREQUENCY = "SET_JOIN_FREQUENCY";
export const SET_JOIN_CHILDS_INFO = "SET_JOIN_CHILDS_INFO";
export const SET_JOIN_PLAN = "SET_JOIN_PLAN";
export const SET_JOIN_PRODUCT = "SET_JOIN_PRODUCT";
export const SET_JOIN_TAX_RATE = "SET_JOIN_TAX_RATE";
export const SET_JOIN_CLIENT_TOKEN = "SET_JOIN_CLIENT_TOKEN";
export const SET_JOIN_STATUS = "SET_JOIN_STATUS";
export const SET_JOIN_PROMO = "SET_JOIN_PROMO";
export const SET_JOIN_REFERRAL = "SET_JOIN_REFERRAL";
export const SET_JOIN_HOLIDAY_CODE = "SET_JOIN_HOLIDAY_CODE";
export const SET_JOIN_HOLIDAY_CODE_TYPE = "SET_JOIN_HOLIDAY_CODE_TYPE";
export const SET_JOIN_CYBER_MONDAY = "SET_JOIN_CYBER_MONDAY";
export const SET_GIFT_REDIRECT = "SET_GIFT_REDIRECT";
export const SET_GIFT_PURCHASE_REDIRECT = "SET_GIFT_PURCHASE_REDIRECT";
export const JOIN_STATUS_PENDING = "pending";
export const JOIN_STATUS_FAIL = "fail";
export const CLEAR_JOIN_STATUS = "CLEAR_JOIN_STATUS";
export const SET_JOIN_ERROR = "SET_JOIN_ERROR";
export const CLEAR_JOIN_ERROR = "CLEAR_JOIN_ERROR";
export const CLEAR_JOIN_DATA = "CLEAR_JOIN_DATA";
export const SET_GIFT_CODE = "SET_GIFT_CODE";
export const SET_GIFT_COUNTRY_CODE = "SET_GIFT_COUNTRY_CODE";
export const SET_PLAN_OPEN_REDIRECT = "SET_PLAN_OPEN_REDIRECT";
export const SET_GROUPON_CODE = "SET_GROUPON_CODE";
export const SET_GIFT_OFFER = "SET_GIFT_OFFER";
export const SET_SURVEY_AUTH = "SET_SURVEY_AUTH";
export const SET_GRIFTER_PROMO_CODE = "SET_GRIFTER_PROMO_CODE";
export const SET_JOIN_EMAIL = "SET_JOIN_EMAIL";
export const SET_JOIN_FREE_BOOK = "SET_JOIN_FREE_BOOK";
export const SET_NEW_MEMBER = "SET_NEW_MEMBER";
export const CLEAR_NEW_MEMBER = "CLEAR_NEW_MEMBER";
export const SET_JOIN_PASSWORD = "SET_JOIN_PASSWORD";
export const SET_JOIN_SHIPPING = "SET_SHIPPING";
export const SET_LOGIN_VIA_FB = "SET_LOGIN_VIA_FB";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_JOIN_PROMO_ERROR = "SET_JOIN_PROMO_ERROR";
export const CLEAR_JOIN_PROMO_ERROR = "CLEAR_JOIN_PROMO_ERROR";
export const SET_JOIN_STEP_SKIPPED_PLAN = "SET_JOIN_STEP_SKIPPED_PLAN";
export const CLEAR_JOIN_STEP_SKIPPED_PLAN = "CLEAR_JOIN_STEP_SKIPPED_PLAN";
export const SET_JOIN_STEP = "SET_JOIN_STEP";
export const SET_JOIN_STEP_DEFAULT = "SET_JOIN_STEP_DEFAULT";
export const JOIN_FREQUENCY = "frequency";
export const JOIN_GENRE = "genre";
export const JOIN_CHILD = "childs-info";
export const JOIN_EMAIL = "email";
export const JOIN_EMAIL_FB = "email-fb";
export const JOIN_SHIPPING = "shipping";
export const JOIN_BILLING = "billing";
export const JOIN_PLAN = "plan";
export const JOIN_CONFIRMATION = "confirmation";
export const JOIN_PRODUCT = "product";
export const JOIN_LOGGING_IN = "logging-in";
export const SET_JOIN_TYPE = "SET_JOIN_TYPE";
export const SET_REJOIN_BASE_PATH = "SET_REJOIN_BASE_PATH";
export const SET_STEP_JOIN_STEP = "SET_STEP_JOIN_STEP";
export const SET_FAUX_BOX = "SET_FAUX_BOX";
export const SET_JOIN_RENEWAL_PLAN = "SET_JOIN_RENEWAL_PLAN";
export const SET_GIFT_REJOIN = "SET_GIFT_REJOIN";
export const SET_AUTO_PROMO_CODE = "SET_AUTO_PROMO_CODE";
