/* eslint-disable react/jsx-key */

import React, { useContext } from "react";
import loadable from "@loadable/component";
import { Route, Switch, useParams } from "react-router-dom";
import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import LoyaltyOverviewSkeleton from "UI/loadingSkeletons/LoyaltyOverviewSkeleton.jsx";
import RafLandingSkeleton from "UI/loadingSkeletons/RafLandingSkeleton.jsx";
import UserFlowsSkeleton from "UI/loadingSkeletons/UserFlowsSkeleton.jsx";
import Authenticate from "UI/skeleton/Authenticate.jsx";
import LeadAuthenticate from "UI/skeleton/LeadAuthenticate.jsx";
import AppFeatureGate from "UI/skeleton/AppFeatureGate.jsx";
import BFFRejoin from "UI/pages/join/BFFRejoin.jsx";
import LoyaltyTierRejoin from "UI/pages/join/LoyaltyTierRejoin";
import { RegionContext } from "UI/contexts/RegionContext";

import * as promoApi from "../state/promo/api";
import { snesRedirect } from "Utils/snesRedirect";

const Rejoin = loadable(() => import("UI/pages/join/Rejoin.jsx"), {
  fallback: <LoadingCoinSkeleton />,
});
const RejoinConfirmation = loadable(
  () => import("UI/pages/join/RejoinConfirmation.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const LoyaltyRejoinConfirmation = loadable(
  () => import("UI/pages/special/loyalty_rejoin/LoyaltyRejoinConfirmation.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const BFFRejoinConfirmation = loadable(
  () => import("UI/pages/join/BFFRejoinConfirmation.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const TierRejoinConfirmation = loadable(
  () => import("UI/pages/join/TierRejoinConfirmation.tsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const UnpauseConfirmation = loadable(
  () => import("UI/pages/join/UnpauseConfirmation.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const JoinConfirmation = loadable(
  () => import("UI/pages/join/JoinConfirmation.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const NewMemberOffer = loadable(
  () => import("UI/pages/special/NewMemberOffer.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const Billing = loadable(() => import("UI/pages/join/Billing.jsx"), {
  fallback: <UserFlowsSkeleton />,
});
const ShipBilling = loadable(() => import("UI/pages/join/ShipBilling.jsx"), {
  fallback: <UserFlowsSkeleton />,
});
const LoyaltyRejoinSelectPlan = loadable(
  () => import("UI/pages/special/loyalty_rejoin/LoyaltyRejoinSelectPlan.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const StepJoin = loadable(() => import("UI/pages/join_steps/StepJoin.jsx"), {
  fallback: <UserFlowsSkeleton />,
});
const CourtesyPolicyStepJoin = loadable(
  () => import("UI/pages/join_steps/CourtesyPolicyStepJoin.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const BoxReview = loadable(() => import("UI/pages/join_steps/BoxReview.jsx"), {
  fallback: <UserFlowsSkeleton />,
});
// const SinglePageJoin = loadable(() => import('UI/pages/join_single_page/SinglePageJoin.jsx'), { fallback: <UserFlowsSkeleton /> });
const PostJoinConfirmation = loadable(
  () => import("UI/pages/post_join/JoinConfirmation.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const PostJoinSurveyFlow = loadable(
  () => import("UI/pages/post_join/SurveyFlow.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const PostJoinLanding = loadable(
  () => import("UI/pages/post_join/PostJoinLanding.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const PostJoinSurveyWrapper = loadable(
  () => import("UI/pages/join/PostJoinSurveyWrapper.jsx"),
  { fallback: <UserFlowsSkeleton /> },
);
const Loyalty = loadable(() => import("UI/pages/loyalty/LoyaltyOverview.jsx"), {
  fallback: <LoyaltyOverviewSkeleton />,
});
const RafLandingPage = loadable(() => import("UI/pages/raf/Landing.jsx"), {
  fallback: <RafLandingSkeleton />,
});
const MembershipUpsell = loadable(
  () => import("UI/pages/membership/MembershipUpsell.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const JoinAccountExists = loadable(
  () => import("UI/pages/join/JoinAccountExists.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);

const Join = (() => [
  <Route exact path="/logging-in" component={JoinAccountExists} />,
  <Route
    exact
    path={[
      "/credit-rejoin/:path?",
      "/credit-rejoin-a/:path?",
      "/credit-rejoin-c/:path?",
      "/credit-rejoin-f/:path?",
      "/sparks-rejoin/:path?",
    ]}
    component={Rejoin}
  />,
  <Route
    exact
    path="/rejoin-confirmation"
    component={() => snesRedirect("snes", "/post-rejoin-survey")}
  />,
  <Route
    exact
    path="/loyalty-rejoin-confirmation"
    component={LoyaltyRejoinConfirmation}
  />,
  <Route
    exact
    path="/bff-rejoin-confirmation"
    component={BFFRejoinConfirmation}
  />,
  <Route
    exact
    path="/tier-rejoin-confirmation"
    component={TierRejoinConfirmation}
  />,
  <Route exact path="/unpause-confirmation" component={UnpauseConfirmation} />,
  <Route exact path="/enroll/confirmation" component={JoinConfirmation} />,
  <Route exact path="/new_member_offer/:code" component={NewMemberOffer} />,
  <Route exact path="/join-billing" component={Billing} />,
  <Route exact path="/ship-billing">
    <LeadAuthenticate>
      <ShipBilling />
    </LeadAuthenticate>
  </Route>,
  <Route exact path="/box-review">
    <LeadAuthenticate>
      <BoxReview />
    </LeadAuthenticate>
  </Route>,
  <Route
    exact
    path="/post-enroll/confirmation"
    component={PostJoinConfirmation}
  />,
  <Route
    exact
    path="/post-enroll/survey/step-flow/:step"
    component={PostJoinSurveyFlow}
  />,
  <Route exact path="/post-enroll/welcome" component={PostJoinLanding} />,
  <Route exact path="/enroll/confirmation" component={JoinConfirmation} />,
  <Route exact path="/step-join" component={StepJoin} />,
  <Route exact path="/courtesy" component={CourtesyPolicyStepJoin} />,
  <Route exact path="/join-confirmation" component={JoinConfirmation} />,
  <Route exact path="/new_member_offer/:code">
    <Authenticate>
      <NewMemberOffer />
    </Authenticate>
  </Route>,
  <Route exact path="/join-offer/:code">
    <LeadAuthenticate>
      {() => {
        const dispatch = useDispatch();
        const { code } = useParams() || {};
        const { locationHeader } = useContext(RegionContext);
        return (
          <Billing
            {...props}
            noExperiment={true}
            didMountCB={() =>
              (async () => {
                const fiveOfferPromo = await promoApi.getPromoToApply(
                  code,
                  200001,
                  1,
                  locationHeader?.countryHeader,
                );
                dispatch(setJoinPromo(fiveOfferPromo));
              })()
            }
          />
        );
      }}
    </LeadAuthenticate>
  </Route>,

  <Route exact path="/loyalty-offer/:code">
    <Switch>
      <Route path="/">
        <Authenticate>
          <ShipBilling
            noExperiment={true}
            isLoyaltyOffer
            confLink={"loyalty"}
            siteEntry={"loyalty-offer"}
          />
        </Authenticate>
      </Route>
      <Route path="/bff/select-plan" component={LoyaltyRejoinSelectPlan} />
    </Switch>
  </Route>,

  ["/bff1", "/bff2", "/bffholiday"].map((route, idx) => (
    <Route exact path={route} key={idx}>
      <Authenticate entryPoint={route}>
        <AppFeatureGate
          featureName="Loyalty Tier"
          activeComponent={<LoyaltyTierRejoin bffEntryPoint={route} />}
          inActiveComponent={<BFFRejoin bffEntryPoint={route} />}
        />
      </Authenticate>
    </Route>
  )),

  <Route exact path={"/friend"}>
    <AppFeatureGate featureName="Loyalty Tier" redirectPath="/bff">
      <Authenticate entryPoint={"/friend"}>
        <LoyaltyTierRejoin bffEntryPoint={"/friend"} />
      </Authenticate>
    </AppFeatureGate>
  </Route>,

  <Route
    exact
    path={["/membership-upgrade/:path", "/membership-upgrade"]}
    component={MembershipUpsell}
  />,
  <Route
    exact
    path={["/survey/:source", "/survey"]}
    component={PostJoinSurveyWrapper}
  />,
  <Route exact path={["/referCode"]} component={RafLandingPage} />,
  <Route
    exact
    path={["/md22/:path", "/md22"]}
    component={() => snesRedirect("snes", "/")}
  ></Route>,
])();

export default Join;
