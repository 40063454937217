/* eslint-disable react/jsx-key */
import React, { lazy, useContext, ReactElement } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import loadable from "@loadable/component";
import AppFeatureGate from "UI/skeleton/AppFeatureGate.jsx";
import LoadingCoinSkeleton from "UI/loadingSkeletons/LoadingCoinSkeleton.jsx";
import LandingVisitorSkeleton from "UI/loadingSkeletons/LandingVisitorSkeleton.jsx";

import AlertPage from "UI/skeleton/AlertPage";
import { snesRedirect } from "Utils/snesRedirect";

const AccessibilityStatement = loadable(
  () => import("UI/pages/cms/AccessibilityStatement.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const JoinTeam = loadable(() => import("UI/pages/cms/JoinTeam"), {
  fallback: <LoadingCoinSkeleton />,
});
const JoinTeamORIG = loadable(() => import("UI/pages/cms/JoinTeamORIG"), {
  fallback: <LoadingCoinSkeleton />,
});
const VirtualBookTour = loadable(
  () => import("UI/pages/virtual_book_tour/VirtualBookTour.jsx"),
  { fallback: <LandingVisitorSkeleton /> },
);

const LeadEmailPrefs = loadable(
  () => import("UI/pages/visitor/LeadEmailPrefs.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const AppOnlyGate = loadable(() => import("UI/skeleton/AppOnlyGate.jsx"), {
  fallback: <LoadingCoinSkeleton />,
});
const CreditPurchaseLanding = loadable(
  () => import("UI/pages/landing_pages/CreditPurchaseLanding.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const RenewalPlanPurchase = loadable(
  () => import("UI/pages/account/RenewalPlanPurchase.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const PlanUpdateConfirmation = loadable(
  () => import("UI/pages/account/PlanUpdateConfirmation.jsx"),
  { fallback: <LoadingCoinSkeleton /> },
);
const RelationshipStatusLanding = loadable(
  () => import("UI/pages/relationship_status/RelationshipStatusLanding.tsx"),
  { fallback: <LoadingCoinSkeleton /> },
);

const Site = (() => [
  ["/virtual-book-tour", "/vbt"].map((path, idx) => (
    <Route exact path={path} key={idx}>
      <VirtualBookTour />
    </Route>
  )),
  <Route
    exact
    path={"/about-us"}
    component={() => snesRedirect("snes", "/about-us")}
  />,
  <Route
    exact
    path={"/blue-box-boutique"}
    component={() => snesRedirect("snes", "/blue-box-boutique")}
  />,
  <Route exact path={"/join-our-team"}>
    <AppFeatureGate
      inActiveComponent={<JoinTeamORIG />}
      activeComponent={<JoinTeam />}
      featureName="Careers Page"
      redirectPath="/"
    ></AppFeatureGate>
  </Route>,
  <Route
    exact
    path="/accessibility-statement"
    component={AccessibilityStatement}
  />,
  <Route exact path="/email" component={LeadEmailPrefs} />,
  <Redirect exact from="/bff-rewards" to="/relationship-status" />,
  <Route exact path={"/cp-entry/:fromApp?"}>
    <AppFeatureGate featureName="Audio Books">
      <CreditPurchaseLanding />
    </AppFeatureGate>
  </Route>,
  <Route exact path={"/plan-update/:plan?"}>
    <AppFeatureGate featureName="Audio Books">
      <RenewalPlanPurchase />
    </AppFeatureGate>
  </Route>,
  <Route exact path={"/update-confirmation/:plan?/:renewal?"}>
    <AppFeatureGate featureName="Audio Books">
      <PlanUpdateConfirmation />
    </AppFeatureGate>
  </Route>,
  <Route
    exact
    path={"/relationship-status"}
    component={RelationshipStatusLanding}
  />,

  <Route
    exact
    path={["/my-connections/:inviteCode", "/my-connections"]}
    component={() => (
      <AlertPage
        imageUrl="//static.bookofthemonth.com/connections/ConnectionsSunsetLight.jpg"
        header="Connections rode into the sunset."
        dek="We hope you continue to connect with friends over your BOTM books, but we ended our Connections feature so that we can focus on making your membership amazing in other ways."
        showLink={false}
      />
    )}
  />,
  <Route
    exact
    path={"/listen"}
    component={() => snesRedirect("snes", "/listen")}
  />,
  <Route
    exact
    path="/cancel/:path?"
    component={() => snesRedirect("snes", "/cancel")}
  />,
  <Route
    exact
    path="/cancel-membership*"
    component={() => snesRedirect("snes", "/cancel")}
  />,
])();

export default Site;
