import React from "react";
import styled from "styled-components";

import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";

type SaveOfferRelationshipBlockProps = {
  hed?: string;
};

const SaveOfferRelationshipBlock: React.FC<SaveOfferRelationshipBlockProps> = ({
  hed,
}) => {
  return (
    <StyledSaveOfferRelationshipBlock>
      <StyledFlexWrapper
        maxWidth={480}
        textAlign="center"
        alignItems="center"
        borderBottom={true}
      >
        <div className="iconContainer"></div>
        <h4 className="hed">You still have an offer to use!</h4>
        <p className="sub">
          If you cancel you will lose your lower price of $9.99 for your
          subscription next month.
        </p>
      </StyledFlexWrapper>
      <StyledFlexWrapper maxWidth={480} textAlign="center" paddingTop={40}>
        <h4>Keep your status!</h4>
        <p className="statusMsg">{hed}</p>
      </StyledFlexWrapper>
    </StyledSaveOfferRelationshipBlock>
  );
};

export const StyledSaveOfferRelationshipBlock = styled.div`
  .iconContainer {
    width: 60px;
    height: 60px;
  }

  .icon {
    width: 100%;
  }

  .hed {
    padding-top: 20px;
  }

  .sub {
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 0;
  }

  .statusMsg {
    padding-top: 20px;
    margin-top: 0;
  }
`;

export default SaveOfferRelationshipBlock;
