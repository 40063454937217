import { IAccount } from "../../../../types/account";
import { ICycle } from "../../../../types/cycle";
import { IPlan } from "../../../../types/plan";
import { IPolicy } from "../../../../types/policy";
import globalScope from "../../global"; // For tests, ugh

export async function history(formmattedForDisplay = false) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account_history${
      formmattedForDisplay ? "?display" : ""
    }`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const accountHistory = await response.json();

  return accountHistory;
}

export async function getEffectivePolicyDate() {
  const response = await fetch(
    `${global.apiHost}/api/account_history/effectiveDate`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  try {
    return await response.json();
  } catch (e) {
    console.error(
      "TCL: AccountHistoryClient -> getEffectivePolicyDate -> e",
      e,
    );
    return null;
  }
}

export async function getAnchorResponse() {
  const response = await fetch(
    `${global.apiHost}/api/v0/account_history/anchorResponse`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  if (response.status === 200) {
    const anchorResponse = await response.json();
    return anchorResponse;
  } else {
    return null;
  }
}

export async function optOutOfLoyaltyTote() {
  const response = await fetch(
    `${global.apiHost}/api/account_history/loyaltyTote?optOut`,
    {
      method: "put",
      body: JSON.stringify({}),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function optIntoLoyaltyTote() {
  const response = await fetch(
    `${global.apiHost}/api/account_history/loyaltyTote?optIn`,
    {
      method: "put",
      body: JSON.stringify({}),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function getLoyaltyStats(accountId: number) {
  const response = await fetch(
    `${global.apiHost}/api/account_history/loyaltyStats`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function getLastShipment() {
  const response = await fetch(
    `${global.apiHost}/api/v0/account_history/lastShipment`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function getDisplayedAccountHistory() {
  const response = await fetch(
    `${global.apiHost}/api/account_history/displayed`,
    {
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function updatePolicy(
  account: IAccount,
  policy: IPolicy,
  currentCycle: ICycle,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account_history/updatePolicy`,
    {
      method: "put",
      body: JSON.stringify({ account, policy, currentCycle }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function updateRenewal(
  account: IAccount,
  plan: IPlan,
  currentCycle: ICycle,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account_history/updateRenewal`,
    {
      method: "put",
      body: JSON.stringify({ account, plan, currentCycle }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function getCreditCommitHistory() {
  const response = await fetch(
    `${global.apiHost}/api/account_history/creditCommitHistory`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}
