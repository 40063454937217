import React from "react";
import styled from "styled-components";
import { MEDIAQUERIES } from "../../../css/Consts.js";
import ClickTracker from "../../elements/ClickTracker";
import ToteActions from "./ToteActions";
import LoyaltyStepBirthday from "../../pages/loyalty/LoyaltyStepBirthday";

type BlockProps = {
  data: {
    image: string;
    imageStyle?: "default" | "cover";
    imageAlt?: string;
    hed?: string;
    superHed?: string;
    dek?: string;
    showClose?: boolean;
    buttonCTA?: string;
    nextStep?: string;
    closeNGoPath?: string;
    logClickData?: string;
    pageCount?: string;
    specialActions?: string;
  };
  setOverlay: (o: null) => void;
  setStep: (step?: string) => void;
  closeNGo: (path?: string) => void;
};

const LoyaltyTierConfContentBlock: React.FC<BlockProps> = ({
  data,
  setOverlay,
  setStep,
  closeNGo,
}) => {
  console.log({ DATAimage: data.image });
  return (
    <StyledContentBlockWrapper>
      <div className={"--modalImageWrapper"}>
        <img
          className={`--modalImage ${data.imageStyle}`}
          src={`${data.image}`}
          alt={data.imageAlt}
        />
      </div>
      <div className={"--modalContent col"}>
        <div className={"--modalHeader"}>
          {data.hed && <h3 className="h2-mobile">{data.hed}</h3>}
          {data.dek && <p>{data.dek}</p>}
        </div>
        <div className={"--modalActions col"}>
          {!data.specialActions && (
            <ClickTracker
              ctaType={"button"}
              id="loyalty-tier-content-block-action"
              style={"primary fullWidth"}
              handleClick={() =>
                data.closeNGoPath
                  ? closeNGo(data.closeNGoPath)
                  : setStep(data.nextStep)
              }
              isPending={false}
              title={data.buttonCTA}
              logClickData={data.logClickData}
            />
          )}
          {data?.specialActions == "toteActions" && (
            <ToteActions setStep={setStep} />
          )}
          {data?.specialActions == "birthdayActions" && (
            <LoyaltyStepBirthday
              nextAction={() => setStep("step4")}
              noHeader
              noPagination
            />
          )}
          {data.pageCount && (
            <p className={"smallText -finePrint"}>{data.pageCount}</p>
          )}
          {data.showClose && (
            <a onClick={() => (setOverlay ? setOverlay(null) : null)}>Close</a>
          )}
        </div>
      </div>
    </StyledContentBlockWrapper>
  );
};

export const StyledContentBlockWrapper = styled.div.attrs({
  className: "sc-StyledContentBlockWrapper" as string,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  .--modalImageWrapper {
    display: flex;
  }
  img.--modalImage.default {
    margin: 50px auto 0;
    height: 100px;
    width: 100px;
  }
  img.--modalImage.cover {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .--modalContent {
    padding: 50px;
    gap: 32px;
    text-align: center;
    p {
      margin-top: 16px;
    }
  }
  .--modalActions {
    gap: 16px;
  }
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    width: 100%;
    img.--modalImage.default {
      margin: 20px auto 0;
      height: 80px;
      width: 80px;
    }
    .--modalContent {
      padding: 20px;
      gap: 24px;
      p {
        margin-top: 8px;
      }
    }
    .--modalActions {
      width: 100%;
      gap: 12px;
    }
    button {
      max-width: 100%;
    }
  }
`;

export default LoyaltyTierConfContentBlock;
