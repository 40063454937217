// App Feature 101 - RAF Skip Renewal Offer

import React from "react";
import showAppFeature from "../../utils/showAppFeature";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { AppFeature101ContextState } from "../../../../types/account";
import { useAsyncEffect } from "../../ui/hooks";
import { IAppFeature } from "../../../../types/app_feature";
import useAccountState from "../../state/account/useAccountState";
import { getRafSkipRenewalOfferData } from "../../state/account/api";
import useAppFeatureState from "../../state/appFeatures/useAppFeatureState";

const initialData = {
  hasEarnedRafSkipRenewalOffer: false,
  rafContent: {
    RAF_MEMBER_PAGE: {
      referralBlock: {
        hed: "",
        dek: "",
      },
      freeMonthDetail: {
        hed: "",
        dek: "",
      },
      expander: {
        hed: "",
        dek: "",
      },
    },
    RAF_SKIP_RENWAL_OFFER_PROMO_BANNER: {
      message: "",
      cta: "",
    },
    RAF_SKIP_RENWAL_OFFER_STATUS_MESSAGE: {
      dek: "",
    },
    RAF_SKIP_RENWAL_OFFER_PROMO_CARD: {
      image: "",
      hed: "",
      dek: "",
      cta: "",
    },
  },
};

const AppFeature101Context = createContext<
  AppFeature101ContextState | undefined
>(undefined);

const AppFeature101Provider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [DATA, setDATA] = useState(initialData);

  const { account } = useAccountState();
  const { appFeatures } = useAppFeatureState() || [];
  const isCancelled = ["Grace Period", "CC Fail"].includes(
    account?.policy?.subType,
  );
  const skipRenewalOffer = appFeatures?.find?.(
    (f: IAppFeature) => f?.feature === "RAF Skip Renewal Offer",
  );
  const showRafSkipRenewalOfferFeature101 =
    showAppFeature(skipRenewalOffer, account?.countryCode || "US") &&
    !isCancelled;

  async function fetchRafSkipRenewalOfferData() {
    try {
      const data: AppFeature101ContextState =
        await getRafSkipRenewalOfferData();
      setDATA((prev) => ({ ...prev, ...data }));
    } catch (e) {
      console.error(e);
    }
  }

  useAsyncEffect(async () => {
    if (showRafSkipRenewalOfferFeature101) {
      try {
        await fetchRafSkipRenewalOfferData();
      } catch (e) {
        console.error(e);
      }
    }
  }, [showRafSkipRenewalOfferFeature101]);

  return (
    <AppFeature101Context.Provider
      value={{
        fetchRafSkipRenewalOfferData,
        showRafSkipRenewalOfferFeature101,
        ...DATA,
      }}
    >
      {children}
    </AppFeature101Context.Provider>
  );
};

const useAppFeature101 = () => {
  const context = useContext(AppFeature101Context);
  if (context === undefined) {
    throw new Error(
      "useAppFeature101 must be used within an AppFeature101Provider",
    );
  }
  return context;
};

const withAppFeature101 = (Component: React.FC) => {
  return (props: any) => {
    return (
      <AppFeature101Provider>
        <Component {...props} />
      </AppFeature101Provider>
    );
  };
};

export { AppFeature101Provider, useAppFeature101, withAppFeature101 };
