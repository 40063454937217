import React from "react";
import styled from "styled-components";
import { FONTS } from "../../../css/Consts";
import Spinner from "../../elements/Spinner";
import { useWindowSize } from "../../hooks/useWindowSize";

export const Link: React.FC<{
  text?: string;
  navigate?: (...args: any) => void;
  linkTo?: string;
  top?: number;
  bottom?: number;
  size?: number;
  underlined?: boolean;
  whiteSpace?: string;
  color?: string;
  isCentered?: boolean;
  isBold?: boolean;
  isPending?: boolean;
  customStyles?: any;
  customClass?: string;
}> = ({
  text,
  navigate,
  linkTo,
  size,
  top,
  bottom,
  underlined,
  whiteSpace,
  color,
  customStyles,
  isBold,
  isPending,
  customClass,
  children,
}) => {
  const [width] = useWindowSize();
  const isMobile = width < 640;
  return (
    <StyledLink
      id={"StyledLink"}
      className={`StyledLink ${customClass}`}
      top={top}
      bottom={bottom}
      size={size}
      underlined={underlined}
      isBold={isBold}
      whiteSpace={whiteSpace}
      color={color}
      onClick={navigate ? () => navigate(linkTo) : () => {}}
      // onKeyPress={() => navigate(linkTo) : () => {}}
      style={{ ...customStyles }}
      isMobile={isMobile}
      tabIndex={0}
    >
      {!isPending && (
        <>
          {children}
          {text}
        </>
      )}
      {isPending && <Spinner size="small" />}
    </StyledLink>
  );
};

export interface IStyledLinkProps {
  top?: number;
  bottom?: number;
  size?: number;
  underlined?: boolean;
  isBold?: boolean;
  whiteSpace?: string;
  color?: string;
  isCentered?: boolean;
  isMobile?: boolean;
}

export const StyledLink = styled.a<IStyledLinkProps>`
  position: relative;
  cursor: hand;
  cursor: pointer;
  margin-top: ${(props) => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 0)};
  white-space: ${(props) => props.whiteSpace || "nowrap"};
  margin-left: ${(props) => (props.isCentered ? "auto" : 0)};
  margin-right: ${(props) => (props.isCentered ? "auto" : 0)};
  font-size: ${(props) => (props.size ? `${props.size}px` : "initial")};
  font-family: ${(props) =>
    props.isBold
      ? FONTS.sansSerifStackBold
      : `"FoldGrotesquePro-Medium", "Helvetica", "Arial", sans-serif`};
  text-decoration: ${(props) => (props.underlined ? "underline" : "none")};
  transition: color 200ms ease-in-out;
  color: ${(props) =>
    props.color ? props.color + "!important" : `var(--color-primary5)`};
  &:hover {
    color: ${(props) =>
      props.color ? props.color + "!important" : `var(--color-primary5)`};
  }
`;

export default Link;
