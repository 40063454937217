import globalScope from "../../global"; // For tests, ugh

export async function get(promoCode: string, trackId = 1, countryCode = "US") {
  const response = await fetch(
    `${global.apiHost}/api/promo/${promoCode}?trackId=${trackId}&countryCode=${countryCode}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function getPromoToApply(
  promoCode: string,
  planId: number,
  trackId = 1,
  countryCode = "US",
) {
  const response = await fetch(
    `${global.apiHost}/api/promo/${promoCode}/${planId}?trackId=${trackId}&countryCode=${countryCode}`,
  );
  if (!response.ok) {
    throw Object.assign(new Error(await response.text()), {
      serverError: true,
    });
  }
  const account = await response.json();
  return await account;
}

export async function getAllPromoBanners(countryCode = "US") {
  const response = await fetch(
    `${global.apiHost}/api/v0/promo_banner/?countryCode=${countryCode}`,
  );
  if (!response.ok) {
    throw Object.assign(new Error(await response.text()), {
      serverError: true,
    });
  }
  const promoBanners = await response.json();
  return await promoBanners;
}

export async function getPromoBanner(id: number, countryCode = "US") {
  const response = await fetch(
    `${global.apiHost}/api/v0/promo_banner/${id}?countryCode=${countryCode}`,
  );
  if (!response.ok) {
    throw Object.assign(new Error(await response.text()), {
      serverError: true,
    });
  }
  const promoBanner = await response.json();
  return await promoBanner;
}

export async function viewPromo(
  promoCode: string,
  planId: number,
  trackId = 1,
  countryCode = "US",
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/promo/view/${promoCode}/${planId}?trackId=${trackId}&countryCode=${countryCode}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}
