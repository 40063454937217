import React from "react";
import { Pointer } from "UI/elements/navigation/Pointers";
import { connect } from "react-redux";
import { logClick } from "State/analytics/flows";
import { COLORS, FONTS } from "CSS/Consts";
import styled from "styled-components";
import { BORDER } from "CSS/Consts";

const Expander = (props) => {
  const expandedText = props.expandedText || "Read less";
  const defaultText = props.defaultText || "Read more";
  const action = props.isExpanded ? props.closeExpander : props.openExpander;
  const color = props.color || COLORS.primary5;
  const size = props.size || 1.8;
  const font = props.isBold
    ? FONTS.sansSerifStackBold
    : FONTS.sansSerifStackRegular;
  const id = props.id || "expander";

  return (
    <StyledWrapper border={props.border}>
      <StyledExpander
        color={color}
        size={size}
        isCentered={props.isCentered}
        font={font}
        border={props.border}
        onClick={() => {
          if (props.trackLabel) {
            logClick(props.trackLabel, { getState: () => props.store });
          }
          action();
        }}
      >
        <h6 className={"h6-alt"} style={{ marginRight: 8 }}>
          {props.isExpanded ? expandedText : defaultText}
        </h6>
        <Pointer
          id={`${id}-pointer`}
          animate={false}
          isActive={!props.isExpanded}
          color={color}
        />
      </StyledExpander>

      {props.isExpanded && props.children}
    </StyledWrapper>
  );
};

export const StyledWrapper = styled.div.attrs({
  className: "sc-StyledWrapper",
})`
  border-top: ${(props) => (props.border ? BORDER.defaultBorder : "none")};
  border-bottom: ${(props) => (props.border ? BORDER.defaultBorder : "none")};
  padding-bottom: ${(props) => (props.border ? 20 : 0)}px;
`;
export const StyledExpander = styled.div.attrs({
  className: "sc-StyledExpander",
})`
  cursor: pointer;
  margin-top: 24px;
  font-family: ${(props) => props.font};
  font-size: ${(props) => `${props.size}rem`};
  display: flex;
  align-content: center;
  justify-content: ${(props) =>
    props.isCentered ? "center" : "space-between"};
  p {
    font-family: ${(props) => props.font};
    font-size: ${(props) => `${props.size}rem`};
    line-height: ${(props) => `${props.size}rem`};
    margin-bottom: 0;
    color: ${(props) => props.color};
    padding-top: 4px;
  }
  &.expanded {
    .pointerDown {
      display: none;
    }
  }
  &.collapsed {
    .pointerUp {
      display: none;
    }
  }
  @media (max-width: 960px) {
    margin-top: 16px;
  }
`;

function mapStateToProps(state) {
  return {
    store: state,
  };
}

export default connect(mapStateToProps)(Expander);
