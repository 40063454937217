import { useContext, useRef, useEffect } from "react";
import { RequestQueueContext } from "../contexts/RequestQueue";
import { useProductLoader } from "./useProduct";
import { useProductDetailLoader } from "./useProductDetail";

import globalScope from "../../global"; // For tests, ugh

import { ProductDetailAPIResponse } from "../../../../types/pdp";
import { UseCategory, GetCategory } from "../../../../types/category";
import { RegionContext } from "../contexts/RegionContext";

/* Version of urlify w.o an id */
export function urlify(title: string): string {
  return title
    .toLowerCase()
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/&.*?;/g, "")
    .replace(/'|\&|\./g, "")
    .split(/[^A-Za-z0-9]+/i)
    .filter((t) => t && t.length > 0)
    .join("-")
    .substr(0, 80);
}

const getCategories = async ({ categoryIds }: { categoryIds: string[] }) => {
  const url = new URL(
    `${global.apiHost}/api/category?list=${encodeURIComponent(
      categoryIds.join(","),
    )}&cycleDelta=0&filter=hardcovers`,
  );
  const isSwagShopList =
    (url.searchParams.get("list") || "").search("swag-shop") > -1;
  isSwagShopList && url.searchParams.delete("filter");
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error(await res.text());
  }
  return await res.json();
};

const useCategoryLoader = () => {
  const loaderCache = useRef(null);
  const { canadaMode } = useContext(RegionContext);
  const { getData, sync } = useContext(RequestQueueContext) as any;
  useEffect(() => {
    sync();
  });

  return (categoryId: string) =>
    getData("category", categoryId, async (categoryIds: string[]) => {
      try {
        const categories = await getCategories({ categoryIds });
        return categories.map((cat: any) => {
          return cat
            ? { pending: false, error: null, data: cat }
            : {
                pending: false,
                error: new Error("Category not found"),
                data: null,
              };
        });
      } catch (err) {
        return categoryIds.map(() => ({
          pending: false,
          error: err,
          data: null,
        }));
      }
    });
};

const useCategory = (categoryUrl: string): UseCategory<GetCategory> => {
  const getCategory = useCategoryLoader();
  return getCategory(categoryUrl);
};

/* Gets products from either immediate children, or first-most leaf node category's children */
const useCategoryProducts = (
  categoryUrl: string,
  usePrefix = true,
  getDetails = false,
): {
  pending: boolean;
  error?: Error | boolean;
  data: ProductDetailAPIResponse[];
} => {
  const getCategory = useCategoryLoader();
  const getProduct = useProductLoader();
  const getProductDetails = useProductDetailLoader();

  const { pending, error, data } = getCategory(categoryUrl);
  if (pending || error) {
    return { pending, error, data };
  }
  const category = data;
  // let urlPrefix = usePrefix ? `${category.url}/` : '';
  // while (category.childType === 'Category') {
  //   const { pending, error, data } = getCategory(category.children[0]['id']);
  //   if (pending || error) {
  //     return { pending, error, data };
  //   }
  //   category = data;
  //   urlPrefix += `${category.url}/`;
  // }

  return {
    pending: false,
    error: false,
    data: category.children.map((p: string, i: number) => {
      const { pending, error, data } = getDetails
        ? getProductDetails(p)
        : getProduct(parseInt(p));
      if (pending || error) {
        return { pending, error, data };
      }
      // Adjust URL for subroute under current category.
      return {
        pending,
        error,
        data: { ...data, isFeatured: category.featured?.[i] || false },
      };
    }),
  };
};

useCategory.displayName = "useCategory";
useCategoryLoader.displayName = "useCategoryLoader";
useCategoryProducts.displayName = "useCategoryProducts";

export { useCategory, useCategoryLoader, useCategoryProducts };
