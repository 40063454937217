import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "./creators";
import * as actionFlows from "./flows";

const useAppFeatureState = () => {
  const appFeatures = useSelector((state: any) => state?.appFeatures);
  const dispatch = useDispatch();

  const actions = bindActionCreators(actionCreators, dispatch);
  const flows = bindActionCreators(actionFlows, dispatch);

  return { appFeatures, ...actions, ...flows };
};

export default useAppFeatureState;
