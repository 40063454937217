import React, { useContext, useState } from "react";
import { connect, useStore } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ClickTracker from "../../elements/ClickTracker";
import ProgressBar from "../../components/ProgessBar";
import { AppState } from "../../../types";
import { MEDIAQUERIES, COLORS, WIDTHS } from "../../../css/Consts.js";
import { RadioButtonList } from "../../elements/form/RadioButton";
import BenefitsList from "../../pages/relationship_status/BenefitsList";
import { useWindowSize } from "../../hooks";
import { RegionContext } from "../../contexts/RegionContext";
import { StyledFlexWrapper } from "../../../ui/components/structure/StyledFlexWrapper";
import { Variant } from "../../../ui/skeleton/Experiments";
import Authenticate from "../../../ui/skeleton/Authenticate";
import SaveOfferRelationshipBlock from "./SaveOfferRelationshipBlock";

type RelationshipStatusProps = {
  exp134?: number;
} & MappedRelationshipStatusProps;

const RelationshipStatus: React.FC<RelationshipStatusProps> = ({
  account,
  exp134,
}) => {
  const { canadaMode } = useContext(RegionContext);
  const [width] = useWindowSize();
  const isMobile = width <= 480;
  const relationshipTier = account?.loyaltyStats?.loyaltyTier;
  const boxes = account?.loyaltyStats?.boxes;
  const [giveItUp, setGiveItUp] = useState<string>("");
  const { push } = useHistory();
  const isCanadianResident = account?.countryCode == "CA" || canadaMode;
  const showNORPauseCancelExp134 = exp134 == 1;
  const NORExpId = showNORPauseCancelExp134
    ? "-NORPauseCancelExp134-RelationshipStatus"
    : "";
  const isCreditCommitSaveOfferClaimed =
    account?.mustRenewSaveOfferStatus == "Claimed";

  const getBoxesUntilTier = (have: number, need: number) => {
    const boxesNeeded = need - have;
    switch (boxesNeeded) {
      case 1:
        return "one";
      case 2:
        return "two";
      case 3:
        return "three";
    }
  };

  const getHed = () => {
    if (relationshipTier == "New Member") {
      return `You’re only ${getBoxesUntilTier(boxes, 3)} box${
        getBoxesUntilTier(boxes, 3) !== "one" ? "es" : ""
      } away from becoming our Friend and getting these sweet perks! You sure?`;
    }
    if (relationshipTier == "Friend") {
      if (boxes <= 9) {
        return "You’re about to lose all these sweet perks. Ready to give all this up?";
      } else {
        return `You’re only ${getBoxesUntilTier(boxes, 12)} box${
          getBoxesUntilTier(boxes, 12) !== "one" ? "es" : ""
        } away from becoming our BFF and getting these sweet perks! You sure?`;
      }
    } else {
      return "You’re about to lose all these sweet perks. Ready to give all this up?";
    }
  };

  const setProgressBar = () => {
    if (relationshipTier == "New Member") {
      return (
        <ProgressBar
          completedAmount={boxes}
          totalAmount={3}
          headline1={"FRIEND"}
          headline2={`${boxes}/3 BOXES`}
          barColor={boxes >= 1 ? COLORS.friendPrimary : COLORS.gray4}
          backgroundColor={COLORS.white}
          completedIcon={""}
          top={0}
          bottom={0}
        />
      );
    } else if (relationshipTier == "Friend" && boxes < 10) {
      return (
        <ProgressBar
          completedAmount={3}
          totalAmount={3}
          headline1={"FRIEND"}
          headline2={`UNLOCKED`}
          barColor={COLORS.friendPrimary}
          backgroundColor={COLORS.white}
          completedIcon={""}
          top={0}
          bottom={23}
        />
      );
    } else {
      return (
        <ProgressBar
          completedAmount={boxes}
          totalAmount={12}
          headline1={"BFF"}
          headline2={
            relationshipTier == "BFF" ? `UNLOCKED` : `${boxes}/12 BOXES`
          }
          barColor={COLORS.bffPrimary}
          backgroundColor={COLORS.white}
          completedIcon={""}
          top={0}
          bottom={0}
        />
      );
    }
  };

  const setButtons = () => {
    const cancelPath = showNORPauseCancelExp134
      ? `/cancel/continue?save=false`
      : `/cancel-membership/confirm`;
    if (giveItUp == "yes") {
      return (
        <>
          <ClickTracker
            ctaType={"button"}
            id={`cancel-continue${NORExpId}`}
            style={"primary fullWidth"}
            linkTo={cancelPath}
            title={"Continue canceling"}
            logClickData={`Cancel - continue canceling ${NORExpId}`}
          />
          <ClickTracker
            ctaType={"button"}
            id={`cancel-keep${NORExpId}`}
            style={"secondary fullWidth"}
            linkTo={"/pause-membership/thanks"}
            title={"Keep my membership"}
            logClickData={`Cancel - keep membership ${NORExpId}`}
          />
          <ClickTracker
            ctaType={"link"}
            id={`cancel-pause${NORExpId}`}
            linkTo={"/pause-membership"}
            title={"Pause my membership"}
            logClickData={`Cancel - pause membership ${NORExpId}`}
          />
        </>
      );
    } else if (giveItUp == "no") {
      return (
        <ClickTracker
          ctaType={"button"}
          id={`cancel-keep${NORExpId}`}
          style={"primary fullWidth"}
          handleClick={() => push("/pause-membership/thanks")}
          title={"Keep my membership"}
          logClickData={`Cancel - keep membership ${NORExpId}`}
        />
      );
    } else {
      return null;
    }
  };

  const setActions = () => {
    if (account?.policy?.type == "Rejoin" && showNORPauseCancelExp134) {
      return (
        <>
          <StyledFlexWrapper maxWidth={480}>
            <ClickTracker
              ctaType={"button"}
              id={`rejoin-link${NORExpId}`}
              style={"primary -fullWidth"}
              linkTo={"/credit-rejoin/step-join"}
              title={`Rejoin now for perks!`}
              logClickData={`Clicked - Rejoin now for perks! ${NORExpId}`}
            />
          </StyledFlexWrapper>
          <StyledFlexWrapper
            maxWidth={480}
            marginTop={isMobile ? 40 : 56}
            paddingTop={isMobile ? 40 : 56}
            borderTop={true}
            gap={isMobile ? 20 : 40}
            textAlign="center"
          >
            <h4>We’d love to hear from you!</h4>
            <ClickTracker
              ctaType={"button"}
              id={`submit-feedback-link${NORExpId}`}
              style={"primary -fullWidth"}
              linkTo={"/cancel-membership/?33"}
              title={`Submit feedback`}
              logClickData={`Clicked - Submit feedback ${NORExpId}`}
            />
          </StyledFlexWrapper>
        </>
      );
    } else {
      return (
        <RadioButtonList
          bottom={20}
          radioButtonGroupName="vbtSubscribe"
          radioButtonValues={[
            {
              text: "Yes, give it all up.",
              value: "yes",
              isChecked: giveItUp == "yes",
            },
            {
              text: "No, I want sweet perks!",
              value: "no",
              isChecked: giveItUp == "no",
            },
          ]}
          handleUpdate={setGiveItUp as (ep?: string | boolean) => void}
        />
      );
    }
  };

  return (
    <Authenticate>
      <StyledFlexWrapper
        id={`RelationshipStatusWrapper`}
        paddingTop={isMobile ? 40 : 56}
        paddingBottom={isMobile ? 80 : 120}
        paddingLeft={isMobile ? 20 : 0}
        paddingRight={isMobile ? 20 : 0}
      >
        {isCreditCommitSaveOfferClaimed ? (
          <SaveOfferRelationshipBlock hed={getHed()} />
        ) : (
          <StyledFlexWrapper maxWidth={480} textAlign="center">
            <h4>{getHed()}</h4>
          </StyledFlexWrapper>
        )}
        <StyledFlexWrapper maxWidth={480} marginTop={40}>
          {setProgressBar()}
          <BenefitsList
            relationshipTier={relationshipTier}
            boxes={boxes}
            top={isMobile ? 40 : 70}
            bottom={0}
            isCanadianResident={isCanadianResident}
          />
          {setActions()}
          <StyledFlexWrapper
            textAlign="center"
            marginTop={isMobile ? 40 : 20}
            gap={20}
          >
            {setButtons()}
          </StyledFlexWrapper>
        </StyledFlexWrapper>
      </StyledFlexWrapper>
    </Authenticate>
  );
};

export const StyledPageNarrowContent = styled.div.attrs({
  className: "sc-StyledPageNarrowContent" as string,
})`
  margin: 20px auto 60px;
  width: 100%;
  max-width: ${WIDTHS.narrow};
  @media screen and (max-width: ${MEDIAQUERIES.mobile}) {
    padding: 0 20px;
  }
`;

export const StyledButtonWrapper = styled.div.attrs({
  className: "sc-StyledButtonWrapper" as string,
})`
  margin: 40px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  a {
    padding-top: 10px;
  }
`;

const RelationshipStatusExp = (props: any) => {
  return (
    <Variant experimentId={134}>
      {({ variant }: { variant: number }) => (
        <RelationshipStatus {...props} exp134={variant} />
      )}
    </Variant>
  );
};

type MappedRelationshipStatusProps = Pick<AppState, "account">;

const mapStateToProps = (state: AppState): MappedRelationshipStatusProps => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps)(RelationshipStatusExp);
