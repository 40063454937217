/**
 * Modal for messaging banned accounts
 * Child of BOM.jsx
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStoreData } from "../../ui/hoc/withStoreData";
import styled from "styled-components";
import { logout } from "../../state/account/flows";
import { AppState, Dispatch } from "../../types";

type BanModalProps = {
  logout: () => void;
  // withStoreData:
  policySubType: string;
} & MappedBanModalProps;

const BanModal: React.FC<BanModalProps> = ({ policySubType, logout }) => {
  const emailAddress = "apphelp@bookofthemonth.com";
  let header, subtext, cta;
  let email = (
    <>
      <a className="nowrap">{emailAddress}</a>
    </>
  );
  switch (policySubType) {
    case "Delete Info":
      header = (
        <>
          Your account is <span className="nowrap">pending deletion.</span>
        </>
      );
      subtext = (
        <>
          Your personal information will be deleted within 48 hours. Please look
          out for an email with more information.
        </>
      );
      cta = (
        <>
          <br />
          <br />
          If you made a mistake please contact Member Services by emailing{" "}
          {email}.
        </>
      );
      break;
    case "Under Age":
      header = <>You do not meet our age requirement.</>;
      subtext = <>This account has been suspended.</>;
      cta = (
        <>
          <br />
          <br />
          If you made a mistake, please contact Member Services by emailing{" "}
          {email}.
        </>
      );
      break;
    case "Chargeback":
    default:
      header = <>Uh oh. Looks like we need to talk.</>;
      subtext = <>This account has been temporarily frozen.</>;
      cta = (
        <>
          <br />
          <br />
          Email us at {email} and we’ll get it all sorted out.
        </>
      );
      break;
  }

  return (
    <StyledBanModal>
      <h3>{header}</h3>
      <br />
      <br />
      <p className="tertiaryColor">
        {subtext}
        {cta}
      </p>
      <a className="smallText link" onClick={() => logout()}>
        Log out
      </a>
    </StyledBanModal>
  );
};

export const StyledBanModal = styled.div`
  text-align: center;
  width: 100%;
  max-width: 594px;
`;

type MappedBanModalProps = Pick<AppState, "account">;

const mapStateToProps = (state: AppState) => {
  return {
    policySubType: state?.account?.policy?.subType || "",
  };
};
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch,
  );
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BanModal);
const componentWithStoreData = withStoreData(connectedComponent);
export default componentWithStoreData;
