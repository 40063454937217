/**
 * Loyalty modals content for birthday step.
 * Child of LoyaltyModal.jsx
 */

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { updateAccount } from "State/account/flows";
import { markMessageAsSeen } from "State/accountMessage/flows";
import FieldsetBirthday from "UI/components/forms/FieldsetBirthday.jsx";
import { getNumericalMonth } from "Utils";
import { COLORS, FONTS } from "CSS/Consts.js";
import { withStoreData } from "UI/hoc/withStoreData";

const LoyaltyStepBirthday = (props) => {
  let { storeData, cycleActions } = props,
    {
      track,
      store: { currentCycle, pickingPeriodOpen },
    } = storeData;

  const [bDayMonth, setBdayMonth] = useState(props.account?.birthday?.month);

  const zeroIndexedMonth = getNumericalMonth(currentCycle);
  const shippedThisCycle = cycleActions?.cycleResponseIsShipment;
  const skippedThisCycle = cycleActions?.cycleResponseIsSkip;
  const birthdayCopy =
    shippedThisCycle || skippedThisCycle || !pickingPeriodOpen
      ? `Since you've already ${
          shippedThisCycle ? "shipped" : "skipped"
        } this month’s box, your free add-on will apply to next month.`
      : "You'll get a free add-on in this month’s box.";

  useEffect(() => {
    try {
      props.markMessageAsSeen(11);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const nextAction = () => {
    if (props.nextAction) {
      props.nextAction();
    } else {
      props.setContent("success");
    }
  };

  const setBdayMonthCb = (month) => {
    setBdayMonth(month);
  };

  const icon = props.forRelationshipTier
    ? `https://static.bookofthemonth.com/Relationship/BFF/BFF_Bday_Book.png`
    : `https://static.bookofthemonth.com/loyalty/illustrations/icon_birthday.svg`;

  return (
    <StyledBirthdayWrapper>
      {!props.noHeader && (
        <>
          <img src={icon} />
          <div className="-content">
            <h4>Ain’t no birthday like a BFF birthday.</h4>
            <p>
              Tell us your birthday and we’ll gift you a free{" "}
              <span className="nowrap">add-on</span> during your birthday month.
            </p>
          </div>
        </>
      )}

      {parseInt(bDayMonth) === parseInt(zeroIndexedMonth) && (
        <div className="happyBirthday">
          <p>Happy birthday! {birthdayCopy}</p>
        </div>
      )}
      <StyledFormWrapper>
        <FieldsetBirthday
          trackLinkLabel={""}
          isFullWidthButton={true}
          nextAction={() => nextAction()}
          cta={"Submit"}
          optional={true}
          monthCB={(month) => setBdayMonthCb(month)}
        />
        {props.overlay?.data?.origin != "banner" && !props.noPagination ? (
          <div style={{ marginTop: "20px" }} className="smallText -finePrint">
            2 of 2
          </div>
        ) : null}
      </StyledFormWrapper>
    </StyledBirthdayWrapper>
  );
};

export const StyledBirthdayWrapper = styled.div.attrs({
  className: "sc-StyledBirthdayWrapper",
})`
  width: 100%;
  text-align: center;
  img {
    height: 90px;
    margin-bottom: 20px;
  }

  .happyBirthday {
    background-color: ${COLORS.gray2};
    border-radius: 4px;
    padding: 12px;
    text-align: left;
    margin: 20px auto 0;
  }

  .happyBirthday p {
    font-family: ${FONTS.sansSerifStackBold};
  }
`;

export const StyledFormWrapper = styled.div.attrs({
  className: "sc-StyledFormWrapper",
})`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .sc-StyledFieldsetWrapper,
  .sc-StyledOuterWrapper {
    width: 100%;
    margin: 0px;
  }
  .sc-StyledFieldsetWrapper {
    gap: 12px;
  }
  @media screen and (max-width: 680px) {
    gap: 24px;
    .sc-StyledFieldsetWrapper {
      gap: 8px;
    }
  }
`;

function mapStateToProps(state) {
  return {
    account: state.account,
    cycleActions: state.account.cycleActions,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateAccount, markMessageAsSeen }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(LoyaltyStepBirthday),
);
