import { SelectOption, FieldSet, ImageData } from "../../elements/form";
import { IssueTypeValue } from ".";
import { mappedIssueTypes } from "./util";
import { Provinces } from "../../elements/form/SelectData";

export const Months = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const States = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "IA", value: "IA" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "PR", value: "PR" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VA", value: "VA" },
  { label: "VT", value: "VT" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" },
  { label: "AA", value: "AA" },
  { label: "AE", value: "AE" },
  { label: "AP", value: "AP" },
];

export const issueTypeOptions: SelectOption[] = [
  {
    label: mappedIssueTypes.CHANGE_ORDER,
    value: mappedIssueTypes.CHANGE_ORDER,
  },
  {
    label: mappedIssueTypes.ORDER_AND_SHIPPING_HELP,
    value: mappedIssueTypes.ORDER_AND_SHIPPING_HELP,
  },
  {
    label: mappedIssueTypes.CREDITS_AND_CHARGES,
    value: mappedIssueTypes.CREDITS_AND_CHARGES,
  },
  {
    label: mappedIssueTypes.SUBSCRIPTION_MANAGEMENT,
    value: mappedIssueTypes.SUBSCRIPTION_MANAGEMENT,
  },
  { label: mappedIssueTypes.GIFTS, value: mappedIssueTypes.GIFTS },
  {
    label: mappedIssueTypes.RELATIONSHIP_STATUS,
    value: mappedIssueTypes.RELATIONSHIP_STATUS,
  },
  {
    label: mappedIssueTypes.TECHINCAL_ISSUE,
    value: mappedIssueTypes.TECHINCAL_ISSUE,
  },
  { label: mappedIssueTypes.AUDIOBOOKS, value: mappedIssueTypes.AUDIOBOOKS },
  {
    label: mappedIssueTypes.GIVE_FEEDBACK,
    value: mappedIssueTypes.GIVE_FEEDBACK,
  },
  {
    label: mappedIssueTypes.SOMETHING_ELSE,
    value: mappedIssueTypes.SOMETHING_ELSE,
  },
];

export const subjectDropdownOptions: {
  [key in IssueTypeValue]: SelectOption[];
} = {
  [mappedIssueTypes.CHANGE_ORDER]: [
    {
      label: "Change address on existing order",
      value: "Change address on existing order",
    },
    {
      label: "Change contents of shipped box",
      value: "Change contents of shipped box",
    },
    { label: "Cancel order", value: "Cancel order" },
    {
      label: "Apply or remove credit to existing order",
      value: "Apply or remove credit to existing order",
    },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.ORDER_AND_SHIPPING_HELP]: [
    { label: "Issue with book", value: "Issue with book" },
    { label: "Get shipping update", value: "Get shipping update" },
    {
      label: `Order marked as delivered but I didn't receive`,
      value: `Order marked as delivered but I didn't receive`,
    },
    { label: "Where is my box", value: "Where is my box" },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.CREDITS_AND_CHARGES]: [
    { label: "Charge explanation", value: "Charge explanation" },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.SUBSCRIPTION_MANAGEMENT]: [
    { label: "Cancel subscription", value: "Cancel subscription" },
    { label: "Pause subscription", value: "Pause subscription" },
    { label: "Skip box", value: "Skip box" },
    { label: "Unskip box", value: "Unskip box" },
    { label: "Request refund", value: "Request refund" },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.GIFTS]: [
    { label: "Help redeeming gift card", value: "Help redeeming gift card" },
    {
      label: "Issue with digital gift card",
      value: "Issue with digital gift card",
    },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.RELATIONSHIP_STATUS]: [
    {
      label: "Birthday coupon not appearing",
      value: "Birthday coupon not appearing",
    },
    { label: "Request tote", value: "Request tote" },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.TECHINCAL_ISSUE]: [
    { label: "Trouble reseting password", value: "Trouble reseting password" },
    { label: `Can't log in`, value: `Can't log in` },
    {
      label: `Can't update payment method`,
      value: `Can't update payment method`,
    },
    { label: "Report app/website issues", value: "Report app/website issues" },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.AUDIOBOOKS]: [
    { label: "Audiobook help", value: "Audiobook help" },
  ],
  [mappedIssueTypes.GIVE_FEEDBACK]: [
    { label: "Shipping", value: "Shipping" },
    { label: "Book selections", value: "Book selections" },
    { label: "Idea", value: "Idea" },
    { label: "Something else", value: "Something else" },
  ],
  [mappedIssueTypes.SOMETHING_ELSE]: [
    { label: "Something else", value: "Something else" },
  ],
};

export const defaultImageData: ImageData = {
  fileName: "",
  metadata: {
    binary: "",
  },
};

export const defaultForm: FieldSet = {
  issueType: {
    name: "issueType",
    type: "select",
    placeholder: "Select issue type",
    label: "Issue type",
    value: "",
    options: issueTypeOptions,
    required: true,
  },
  subject: {
    name: "subject",
    type: "select",
    placeholder: "Select subject",
    label: "Subject",
    value: "",
    options: [{ label: "", value: "" }],
    required: true,
    visible: true,
  },
  name: {
    name: "name",
    type: "text",
    placeholder: "Your full name",
    label: "Your full name",
    value: "",
    required: true,
  },
  email: {
    name: "email",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
  },
  description: {
    name: "description",
    type: "textArea",
    placeholder: "Tell us more",
    label: "Tell us more",
    value: "",
  },
  cta: {
    name: "cta",
    type: "cta",
    placeholder: "",
    label: "Submit",
    value: "",
  },
};

export const bffBirthdayForm: FieldSet = {
  issueType: {
    name: "issueType",
    type: "select",
    placeholder: "Select issue type",
    label: "Issue type",
    value: "",
    options: issueTypeOptions,
    required: true,
  },
  subject: {
    name: "subject",
    type: "select",
    placeholder: "Select subject",
    label: "Subject",
    value: "",
    options: [{ label: "", value: "" }],
    required: true,
    visible: true,
  },
  memo: {
    name: "memo",
    type: "memo",
    placeholder: "",
    label: "",
    value:
      "Members who have achieved BFF status get a free coupon at beginning of their birthday month.",
  },
  name: {
    name: "name",
    type: "text",
    placeholder: "Your full name",
    label: "Your full name",
    value: "",
    required: true,
  },
  email: {
    name: "email",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
  },
  title: {
    name: "title",
    type: "title",
    placeholder: "",
    label: "",
    value: "Birthday",
    desktopSize: "full",
  },
  month: {
    name: "month",
    type: "select",
    placeholder: "Month",
    label: "Month",
    value: "",
    options: Months,
    mobileSize: "half",
    desktopSize: "half",
    required: true,
    visible: true,
  },
  year: {
    name: "year",
    type: "number",
    placeholder: "Year",
    label: "Year",
    value: "",
    mobileSize: "half",
    desktopSize: "half",
    required: true,
  },
  cta: {
    name: "cta",
    type: "cta",
    placeholder: "",
    label: "Submit",
    value: "",
  },
};

export const addressFields: FieldSet = {
  country: {
    name: "country",
    type: "select",
    placeholder: "Country",
    label: "Country",
    value: "",
    options: [
      { label: "US", value: "US" },
      { label: "Canada", value: "CA" },
    ],
    required: true,
  },
  address: {
    name: "address",
    type: "text",
    placeholder: "New address",
    label: "New address",
    value: "",
    mobileSize: "full",
    desktopSize: "halfPlus",
    required: true,
  },
  apt: {
    name: "apt",
    type: "text",
    placeholder: "Apt./suite",
    label: "Apt./suite",
    value: "",
    mobileSize: "half",
    desktopSize: "halfMinus",
  },
  city: {
    name: "city",
    type: "text",
    placeholder: "City",
    label: "City",
    value: "",
    mobileSize: "half",
    desktopSize: "third",
    required: true,
  },
  state: {
    name: "state",
    type: "select",
    placeholder: "State",
    label: "State",
    value: "",
    options: States,
    mobileSize: "half",
    desktopSize: "third",
    required: true,
    visible: true,
  },
  province: {
    name: "province",
    type: "select",
    placeholder: "Province",
    label: "Province",
    value: "",
    options: Provinces,
    mobileSize: "half",
    desktopSize: "third",
    required: false,
    visible: false,
  },
  zip: {
    name: "zip",
    type: "number",
    placeholder: "Zip",
    label: "Zip",
    value: "",
    mobileSize: "half",
    desktopSize: "third",
    required: true,
  },
};

export const findMyboxForm: FieldSet = {
  issueType: {
    name: "issueType",
    type: "select",
    placeholder: "Select issue type",
    label: "Issue type",
    value: "",
    options: issueTypeOptions,
    required: true,
  },
  subject: {
    name: "subject",
    type: "select",
    placeholder: "Select subject",
    label: "Subject",
    value: "",
    options: [{ label: "", value: "" }],
    required: true,
  },
  name: {
    name: "name",
    type: "text",
    placeholder: "Your full name",
    label: "Your full name",
    value: "",
    required: true,
  },
  email: {
    name: "email",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
  },
  ...addressFields,
  cta: {
    name: "cta",
    type: "cta",
    placeholder: "",
    label: "Submit",
    value: "",
  },
};

export const orderMarkedAsDeliveredButDidntReceive: FieldSet = {
  issueType: {
    name: "issueType",
    type: "select",
    placeholder: "Select issue type",
    label: "Issue type",
    value: "",
    options: issueTypeOptions,
    required: true,
  },
  subject: {
    name: "subject",
    type: "select",
    placeholder: "Select subject",
    label: "Subject",
    value: "",
    options: [{ label: "", value: "" }],
    required: true,
  },
  memo: {
    name: "memo",
    type: "memo",
    placeholder: "",
    label: "",
    value:
      "We ask you to wait 24 hours after stated delivery, as many members report their box showing up the next day.",
  },
  name: {
    name: "name",
    type: "text",
    placeholder: "Your full name",
    label: "Your full name",
    value: "",
    required: true,
  },
  email: {
    name: "email",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
  },
  ...addressFields,
  cta: {
    name: "cta",
    type: "cta",
    placeholder: "",
    label: "Submit",
    value: "",
  },
};

export const changeAddressForm: FieldSet = {
  issueType: {
    name: "issueType",
    type: "select",
    placeholder: "Select issue type",
    label: "Issue type",
    value: "",
    options: issueTypeOptions,
    required: true,
  },
  subject: {
    name: "subject",
    type: "select",
    placeholder: "Select subject",
    label: "Subject",
    value: "",
    options: [{ label: "", value: "" }],
    required: true,
  },
  name: {
    name: "name",
    type: "text",
    placeholder: "Your full name",
    label: "Your full name",
    value: "",
    required: true,
  },
  email: {
    name: "email",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
  },
  ...addressFields,
  description: {
    name: "description",
    type: "textArea",
    placeholder: "Anything else we should know?",
    label: "Anything else we should know?",
    value: "",
  },
  cta: {
    name: "cta",
    type: "cta",
    placeholder: "",
    label: "Submit",
    value: "",
  },
};

export const issueWithBookForm: FieldSet = {
  issueType: {
    name: "issueType",
    type: "select",
    placeholder: "Select issue type",
    label: "Issue type",
    value: "",
    options: issueTypeOptions,
    required: true,
  },
  subject: {
    name: "subject",
    type: "select",
    placeholder: "Select subject",
    label: "Subject",
    value: "",
    options: [{ label: "", value: "" }],
    required: true,
  },
  bookIssueType: {
    name: "bookIssueType",
    type: "select",
    placeholder: "Type of issue",
    label: "Type of issue",
    value: "",
    options: [
      { label: "Damaged", value: "Damaged" },
      { label: "Wrong book", value: "Wrong book" },
      { label: "Missing book", value: "Missing book" },
      { label: "Misprinted book", value: "Misprinted book" },
      { label: "Something else", value: "Something else" },
    ],
    required: true,
  },
  bookInQuestion: {
    name: "bookInQuestion",
    type: "text",
    placeholder: "Book in question",
    label: "Book in question",
    value: "",
    required: true,
    labelForErrorMessage: "book",
  },
  name: {
    name: "name",
    type: "text",
    placeholder: "Your full name",
    label: "Your full name",
    value: "",
    required: true,
  },
  email: {
    name: "email",
    type: "text",
    placeholder: "Your email",
    label: "Your email",
    value: "",
    required: true,
  },
  description: {
    name: "description",
    type: "textArea",
    placeholder: "Tell us more",
    label: "Tell us more",
    value: "",
  },
  // 'attachment' : {
  //   name: 'attachment',
  //   type: 'attachment',
  //   placeholder: '',
  //   label: '',
  //   value: defaultImageData,
  //   // TODO this will have to be controlled in a manner similar to country and state/provinces once attach picture ticket is ready
  //   visible: true,
  //   required: false,
  // },
  cta: {
    name: "cta",
    type: "cta",
    placeholder: "",
    label: "Submit",
    value: "",
  },
};
