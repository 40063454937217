import { useContext, useEffect, useRef } from "react";
import { RequestQueueContext } from "../contexts/RequestQueue";

const getBoxStatus = async (cycleId) => {
  const res = await fetch(
    `${global.apiHost}/api/box/box_status${cycleId ? "/" + cycleId : ""}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  if (!res.ok) {
    throw new Error(await res.text());
  }
  return await res.json();
};

const useBoxStatusLoader = () => {
  const { getData, sync } = useContext(RequestQueueContext);
  useEffect(() => {
    sync();
  });

  return (cycleId, forceRefresh = false) =>
    getData(
      "boxStatus",
      cycleId,
      async (cycleIds) => {
        const responses = [];
        for (let cycleId of cycleIds) {
          try {
            const boxStatus = await getBoxStatus(cycleId);
            responses.push({ pending: false, error: null, data: boxStatus });
          } catch (err) {
            responses.push({ pending: false, error: err, data: null });
          }
        }
        return responses;
      },
      forceRefresh,
    );
};

const useBoxStatus = (cycleId, forceRefresh = false) => {
  const getBoxStatuses = useBoxStatusLoader();
  return getBoxStatuses(cycleId, forceRefresh);
};

export { useBoxStatus, useBoxStatusLoader };
