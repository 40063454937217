import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { setOverlay } from "State/ui/creators";
import styled from "styled-components";
import { _MONTHS } from "Utils";
import { Button } from "UI/elements/form/Button";
import { snesRedirect } from "Utils/snesRedirect";

const LoyaltyBirthdayMonthModal = (props) => {
  let { overlay } = props;
  if (!overlay || (overlay && overlay.name !== "LoyaltyBirthdayMonthModal")) {
    return null;
  }

  const chooseBotm = async () => {
    props.setOverlay(null);
    snesRedirect("snes", "/the-best-new-books");
  };

  return (
    <StyledLoyaltyBirthdayModal id={"StyledLoyaltyBirthdayModal"}>
      <img
        src={`//static.bookofthemonth.com/surveyImages/BFF_birthday.png`}
        alt="It’s your birthday month"
      />
      <StyledLoyaltyBirthdayModalContent>
        <h3 className={"h2-mobile"}>Hey BFF — it’s your birthday month!</h3>
        <p>
          To celebrate, your {_MONTHS[overlay?.data?.birthdaymonth]} add-on is
          on the house! We put a coupon in your box for this month only.
          Remember <span className="nowrap">to redeem it!</span>
        </p>
        <Button
          top={20}
          id="choose botm"
          style="primary fullWidth"
          handleClick={chooseBotm}
          isPending={false}
          title={"Choose your BOTM"}
        />
      </StyledLoyaltyBirthdayModalContent>
    </StyledLoyaltyBirthdayModal>
  );
};

export const StyledLoyaltyBirthdayModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  img {
    width: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 680px) {
    max-width: 100%;
  }
`;

export const StyledLoyaltyBirthdayModalContent = styled.div`
  text-align: center;
  padding: 50px;
  p {
    margin-top: 16px;
  }
  @media screen and (max-width: 680px) {
    padding: 24px 20px 0;
    p {
      margin-top: 8px;
    }
  }
`;

function mapStateToProps(state) {
  return {
    overlay: state.ui ? state.ui.overlay : null,
    account: state.account,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setOverlay, push }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoyaltyBirthdayMonthModal);
