/**
 * Displays credit/membership messaging within the credits nav.
 * Child of CreditsNav.jsx
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { setCreditNavStatus } from "State/box/creators";
import { isYearlyMember } from "State/account/selectors";
import dateformat from "dateformat";

import { setOverlay } from "State/ui/creators";
import { Button } from "UI/elements/form/Button";
import ModalClose from "UI/components/ModalClose.jsx";
import ClickTracker from "UI/elements/ClickTracker";
import { withStoreData } from "UI/hoc";
import { getGracePeriodCancelDate } from "Utils/";
import { withRegion } from "UI/hoc";
import { snesRedirect } from "Utils/snesRedirect";

export class CreditsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextCycle: {},
      headline: "",
      message: "",
      actionMessage: "",
      action1: "",
      modalType: "",
      action2: (
        <ClickTracker
          ctaType={"button"}
          id="See-the-books"
          handleClick={() => snesRedirect("snes", "/all-hardcovers")}
          title={`See the books`}
          style="primary fullWidth"
          logClickData={"Clicked - see add ons from Addons block"}
        />
      ),
      defaultAction: props.canadaMode ? null : (
        <Button
          aria-hidden={true}
          tabIndex={props.showCreditNav ? "0" : "-1"}
          style="primary -fullWidth"
          onClick={() => this.navigate("/refer-a-friend")}
          title="Invite friends"
        />
      ),
      pendingSkip: false,
      tabIndex: "-1",
    };
    this.setStatus = this.setStatus.bind(this);
    this.close = this.close.bind(this);
    this.openSkipModal = this.openSkipModal.bind(this);
  }

  componentDidMount() {
    const { storeData } = this.props,
      { getCycleRelative } = storeData,
      nextCycle = getCycleRelative(1);
    this.setState({ nextCycle }, () => this.getMessage());
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (
        this.props.path !== prevProps.path &&
        prevProps.overlay &&
        prevProps.overlay.name == "creditsDashboard"
      ) {
        this.props.setOverlay("");
      }
      if (
        (this.props.policy &&
          prevProps.policy &&
          this.props.policy.id !== prevProps.policy.id) ||
        (this.props.account &&
          prevProps.account &&
          this.props.account?.credits !== prevProps.account?.credits)
      ) {
        this.getMessage();
      }
      if (prevProps.account?.renewalPlan !== this.props.account?.renewalPlan) {
        this.getMessage();
      }
      if (
        this.props.showCreditNav !== prevProps.showCreditNav &&
        this.props.showCreditNav
      ) {
        this.setState(
          {
            tabIndex: "0",
          },
          () => this.getMessage(),
        );
      }
    }
  }

  setStatus(status) {
    this.props.setCreditNavStatus(status);
  }

  openSkipModal() {
    this.setStatus({ type: "skip", status: "" });
    this.props.setOverlay("SkipSurveyModal", "", {
      currentMonth: this.props.currentMonth,
    });
  }

  navigate(path) {
    this.props.push(path);
  }

  close() {
    this.props.setOverlay("");
  }

  setPauseMessage() {
    let monthsText = this.props.pickingPeriodOpen
      ? this.props.currentMonth + "'s"
      : "NEXT";
    this.setState({
      headline: "Your subscription is currently paused",
      message: `Get one of ${monthsText} best books when you resume.`,
      action2: (
        <Button
          aria-hidden={true}
          tabIndex={this.state.tabIndex}
          style="primary -fullWidth"
          onClick={() => this.navigate("/credit-rejoin/step-join")}
          title="Resume subscription"
        />
      ),
    });
  }

  setNoCycleResponseMessage() {
    let { isYearlyMember, pickingPeriodOpen, account, policy, currentCycle } =
      this.props;
    const showGiftRenewalNudge =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      !account?.renewalPlan;
    const isRenewingGiftMember =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      account?.renewalPlan &&
      !account?.paymentMethod;
    const overideAction2 = showGiftRenewalNudge || isRenewingGiftMember;
    const { nextCycle } = this.state,
      { label: nextCycleLabel } = nextCycle;
    const isCCFail = account?.policy?.subType === "CC Fail";
    const cancelDate =
      account?.policy?.type === "Rejoin" ? account?.policyEffectiveDate : null;
    const cancellingDate = getGracePeriodCancelDate(
      account?.policyEffectiveDate,
    );

    //Is cancelled
    if (cancelDate && !account?.policy?.canShip) {
      if (account?.credits > 0) {
        this.setState({
          headline: isCCFail
            ? `Your renewal failed on ${dateformat(cancelDate, "UTC:mmmm dd")}.`
            : "Your membership was canceled.",
          message: isCCFail
            ? "Update your payment method for more great reads!"
            : `Your membership was canceled, and your remaining book credits will expire on ${dateformat(
                cancellingDate,
                "UTC:mmmm dd",
              )}.`,
          action2: isCCFail ? (
            <Button
              aria-hidden={true}
              tabIndex={this.state.tabIndex}
              style="primary -fullWidth"
              onClick={() => this.props.setOverlay("ShipBillingModal")}
              title="Update your card"
            />
          ) : (
            <Button
              style="primary -fullWidth"
              onClick={() => snesRedirect("snes", "/the-best-new-books")}
              title="Shop now"
            />
          ),
        });
      } else {
        this.setState({
          headline: isCCFail
            ? `Your renewal failed on ${dateformat(cancelDate, "UTC:mmmm dd")}.`
            : `Your membership was cancelled on ${dateformat(
                cancelDate,
                "UTC:mmmm dd",
              )}.`,
          message: isCCFail
            ? "Update your payment method for more great reads!"
            : "Rejoin today for more great reads!",
          action2: isCCFail ? (
            <Button
              aria-hidden={true}
              tabIndex={this.state.tabIndex}
              style="primary -fullWidth"
              onClick={() => this.props.setOverlay("ShipBillingModal")}
              title="Update your card"
            />
          ) : (
            <Button
              style="primary -fullWidth"
              onClick={() => this.navigate("/credit-rejoin/step-join")}
              title="Rejoin now"
            />
          ),
        });
      }
    }
    //Failed renewal
    else if (policy?.subType === "CC Fail") {
      this.setState({
        headline: "Your membership failed to renew.",
        message: "Update your account to continue your membership.",
        action2: (
          <Button
            aria-hidden={true}
            tabIndex={this.state.tabIndex}
            id="Skip CreditsDashboard"
            style={`primary`}
            onClick={() => this.props.setOverlay("ShipBillingModal")}
            title={"Update now"}
          />
        ),
      });
    }
    // gift renewal nudge for gift members without a renewal plan
    else if (overideAction2) {
      this.setState({
        action1: (
          <ClickTracker
            ctaType={"button"}
            id="See-the-books"
            handleClick={() => snesRedirect("snes", "/all-hardcovers")}
            title={`See the books`}
            style="primary fullWidth"
            logClickData={"Clicked - see add ons from Addons block"}
          />
        ),
      });
    }
    //Has no credits
    else if (pickingPeriodOpen && account?.credits <= 0) {
      this.setState({
        actionMessage: (
          <div className="finePrint" style={{ margin: "0 20px 5px" }}>
            You can't skip when you have a zero balance.
          </div>
        ),
        action2: (
          <ClickTracker
            ctaType={"button"}
            id="See-the-books"
            handleClick={() => snesRedirect("snes", "/all-hardcovers")}
            title={`See the books`}
            style="primary fullWidth"
            logClickData={"Clicked - see add ons from Addons block"}
          />
        ),
      });
    }
    //Closed Picking period
    else if (!pickingPeriodOpen) {
      this.setState({
        headline: `${currentCycle.label} selections are closed.`,
        action2: this.state.defaultAction,
      });
      if (!account?.cycles && !account?.renewalPlan) {
        this.setState({
          message: `Your membership will expire at the end of this month.`,
        });
      } else if (
        (policy?.type == "Member" && policy?.subType == "Gift") ||
        (isYearlyMember && account?.cycles > 0)
      ) {
        this.setState({
          message: `You didn’t choose a book this month. Come back next month to choose your ${nextCycleLabel} book.`,
        });
      } else if (account?.enrollCycle?.id == currentCycle?.id) {
        this.setState({
          headline: `This is your first month, and you have  ${
            account?.credits
          } ${account?.credits > 1 ? " book credits" : " book credit"}.`,
          message: `Come back on the 1st of next month to choose a new book or skip the month.`,
        });
      } else {
        this.setState({
          message: `You didn’t choose a book this month, and you’ll be renewed for ${
            account?.renewalPlan?.credits
          } credit${account?.renewalPlan?.credits > 1 ? "s" : ""} on ${
            this.renewsDate
          }.`,
        });
      }
    }
  }

  setHasShippedMessage() {
    let { isYearlyMember, account, currentCycle, policy, canadaMode } =
      this.props;
    const { nextCycle } = this.state,
      { label: nextCycleLabel } = nextCycle;
    const showGiftRenewalNudge =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      !account?.renewalPlan;
    const isRenewingGiftMember =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      account?.renewalPlan &&
      !account?.paymentMethod;
    const overideAction2 = showGiftRenewalNudge || isRenewingGiftMember;

    if (policy.type == "Member" && policy.subType !== "Gift") {
      // Member and not gift with cycles remaining
      this.setState({
        headline: `Your ${currentCycle.label} box has shipped!`,
        action2: this.state.defaultAction,
      });
      if (isYearlyMember) {
        if (account?.credits > 1) {
          this.setState({
            message: `Come back next month to choose your ${nextCycleLabel} BOTM.`,
          });
        } else {
          this.setState({
            message: `Your yearly membership will renew for 12 credits on ${this.renewsDate}.`,
          });
        }
      } else {
        this.setState({
          message: `You will be renewed for ${
            account?.renewalPlan?.credits
          } book credit${account?.renewalPlan?.credits > 1 ? "s" : ""} on ${
            this.renewsDate
          }.`,
        });
      }
    } else {
      // They are expiring gift member
      if (!account?.cycles) {
        this.setState({
          headline: `Your ${currentCycle.label} box has shipped!`,
          message: `Your membership will expire at the end of this month.`,
          action1: null,
          action2: this.state.defaultAction,
        });
      } else {
        this.setState({
          headline: `Your ${currentCycle.label} box has shipped!`,
          message: `Come back next month to choose your ${nextCycleLabel} BOTM.`,
          action1: null,
          action2: this.state.defaultAction,
        });
      }
    }

    // gift renewal nudge for gift members without a renewal plan
    if (overideAction2 && !canadaMode) {
      this.setState({
        action1: (
          <Button
            aria-hidden={true}
            tabIndex={this.state.tabIndex}
            id="RAF CreditsDashboard"
            style={`secondary -fullWidth`}
            onClick={() => this.navigate("/refer-a-friend")}
            title={"Invite friends"}
          />
        ),
      });
    }
    if (account?.policy?.subType === "CC Fail") {
      this.setState({
        action1: (
          <Button
            aria-hidden={true}
            tabIndex={this.state.tabIndex}
            id="RAF CreditsDashboard"
            style={`primary -fullWidth`}
            onClick={() => this.props.setOverlay("ShipBillingModal")}
            title={"Update your card"}
          />
        ),
        action2: null,
      });
    }
  }

  setHasSkippedMessage() {
    let {
      isYearlyMember,
      currentCycle,
      account,
      showFlexSelectionsNewCopy,
      canadaMode,
    } = this.props;
    const { nextCycle } = this.state,
      { label: nextCycleLabel } = nextCycle;
    const showGiftRenewalNudge =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      !account?.renewalPlan;
    const isRenewingGiftMember =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      account?.renewalPlan &&
      !account?.paymentMethod;
    const overideAction2 = showGiftRenewalNudge || isRenewingGiftMember;

    this.setState({
      headline: `You skipped ${currentCycle.label}.`,
      action2: this.state.defaultAction,
    });

    if (isYearlyMember) {
      if (account?.credits > 1) {
        this.setState({
          message: "Your credits will rollover to next month.",
        });
      } else {
        this.setState({
          message: "Your credit will rollover to next month.",
        });
      }
    } else if (
      account?.enrollCycle?.id == currentCycle?.id &&
      account?.cycleActions?.activeSkipped
    ) {
      // First cycle - new enroll, new members active skip
      this.setState({
        message: `You won’t be charged for your ${nextCycleLabel} renewal, and you can save your credits for future months.`,
      });
    } else {
      if (account?.cycleActions?.activeSkipped) {
        //Memeber actively skipped
        this.setState({
          message: `You won’t be charged for a renewal this month, and your existing book credit(s) will roll over to next month.`,
        });
      } else {
        //Passive skip
        this.setState({
          headline: `You didn’t choose a book this month.`,
          message: `Come back ${nextCycleLabel} 1, ${
            showFlexSelectionsNewCopy
              ? "when we’ll have brand new selections for you to choose from!"
              : "when we’ll announce our 5 selections."
          }`,
        });
      }
    }
    // gift renewal nudge for gift members without a renewal plan
    if (overideAction2 && !canadaMode) {
      this.setState({
        action1: (
          <Button
            aria-hidden={true}
            tabIndex={this.state.tabIndex}
            id="RAF CreditsDashboard"
            style={`secondary -fullWidth`}
            onClick={() => this.navigate("/refer-a-friend")}
            title={"Invite friends"}
          />
        ),
      });
    }
    if (account?.policy?.subType === "CC Fail") {
      this.setState({
        action1: (
          <Button
            aria-hidden={true}
            tabIndex={this.state.tabIndex}
            id="RAF CreditsDashboard"
            style={`primary -fullWidth`}
            onClick={() => this.props.setOverlay("ShipBillingModal")}
            title={"Update your card"}
          />
        ),
        action2: null,
      });
    }
  }

  setHasRenewedMessage() {
    let { account } = this.props;
    const { nextCycle } = this.state,
      { label: nextCycleLabel } = nextCycle;
    this.setState({
      headline: `You were renewed for ${
        account?.renewalPlan?.credits
      } book credit${account?.renewalPlan?.credits > 1 ? "s" : ""} on ${
        this.renewsDate
      }.`,
      message: `Come back ${nextCycleLabel} 1, when we’ll have ${
        this.props.showFlexSelectionsNewCopy ? "brand" : "five"
      } new selections for you to choose from!`,
      action2: this.state.defaultAction,
    });
  }

  getMessage() {
    let { creditNavStatus, account, policy, hasRenewed, currentCycle } =
      this.props;
    let { renewalDate: renewsDateRaw } = account;
    let { modalType, tabIndex } = this.state;
    this.renewsDate =
      Date.parse(renewsDateRaw) > 0 &&
      dateformat(renewsDateRaw, "UTC:mmmm dd, yyyy");

    // This code assumes the JSON-ified history object.
    const cycleActions = account && account.cycleActions;
    //Set defaults
    this.setState({
      headline: `${currentCycle.label}  selections are here!`,
      message: `Choose a Book of the Month by ${currentCycle.label} 21.`,
      action1: null,
      action2: (
        <ClickTracker
          ctaType={"button"}
          id="See-the-books"
          handleClick={() => snesRedirect("snes", "/all-hardcovers")}
          title={`See the books`}
          style="primary fullWidth"
          logClickData={"Clicked - see add ons from Addons block"}
        />
      ),
    });

    if (policy && policy?.subType === "Pause") {
      this.setPauseMessage();
    } // For paused members
    else if (!cycleActions?.hasCycleResponse) {
      this.setNoCycleResponseMessage();
    } //For members with no response recorded yet for this cycle
    else if (cycleActions?.hasCycleResponse) {
      if (cycleActions?.cycleResponseIsShipment) {
        this.setHasShippedMessage();
      } //For members who shipped in current cycle
      else if (cycleActions?.cycleResponseIsSkip) {
        this.setHasSkippedMessage();
      } //For member who skipped current cycle
      else if (hasRenewed) {
        this.setHasRenewedMessage();
      } //for member who were renewed
    }
  }

  render() {
    let { account, creditNavStatus, overlay } = this.props,
      {
        modalType,
        headline,
        message,
        forceClose,
        action1,
        action2,
        actionMessage,
      } = this.state,
      credits = this.props.account?.credits,
      isSkipping = creditNavStatus === "skipping",
      showGiftRenewalNudge =
        account?.policy?.type == "Member" &&
        account?.policy?.subType == "Gift" &&
        !account?.renewalPlan &&
        creditNavStatus !== "skipping";

    const isRenewingGiftMember =
      account?.policy?.type == "Member" &&
      account?.policy?.subType == "Gift" &&
      account?.renewalPlan &&
      !account?.paymentMethod;

    const giftRenewalNudgeAction = (
      <ClickTracker
        aria-hidden={true}
        tabIndex={this.state.tabIndex}
        ctaType={"button"}
        id="Choose RenewalPlan CreditsDashboard"
        style={"primary fullWidth"}
        handleClick={() => snesRedirect("snes", "/my-account/gift-offer")}
        title={"Choose a renewal plan"}
        logClickData={"click_renew_creditmodalweb"}
        top={10}
      />
    );

    const giftPreenrollAction = (
      <Button
        aria-hidden={true}
        tabIndex={this.state.tabIndex}
        id="Gift renewal payment add"
        style={`primary -fullWidth`}
        top={20}
        onClick={() => {
          this.navigate("/gift/preenroll");
        }}
        title={"Add billing info"}
      />
    );

    if (forceClose) {
      return null;
    }

    return (
      <>
        <div
          aria-hidden={true}
          className={
            "navModal creditsDashboard" +
            (overlay && overlay.name == "creditsDashboard"
              ? " showing"
              : " hidden")
          }
        >
          {!isSkipping && (
            <div className="mobileHeader">
              <h5 className="miniText -bold">Your credits</h5>
              <ModalClose action={this.close} />
            </div>
          )}

          <div className="innerWrapper">
            <div className="navModalWrapper">
              <div className="navPointer" />

              <div className="countWrapper">
                <div className="creditCounter">
                  <h5 className="miniText">{credits}</h5>
                </div>
                <p className="p1">
                  {"Book credit" + (credits !== 1 ? "s" : "") + " available"}
                </p>
              </div>

              <div className="messageBlock">
                <p className="h6-alt">{headline}</p>
                <p className="p2">{message}</p>
                <div
                  className={
                    showGiftRenewalNudge || isRenewingGiftMember
                      ? "giftActionWrapper"
                      : "actionWrapper"
                  }
                >
                  {action1}
                  {showGiftRenewalNudge
                    ? giftRenewalNudgeAction
                    : isRenewingGiftMember
                    ? giftPreenrollAction
                    : action2}
                </div>
                {actionMessage}
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => this.close()}
          className={
            "modalWrapper" +
            (isSkipping || (overlay && overlay.name == "creditsDashboard")
              ? " showing"
              : " hidden")
          }
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { store } = ownProps.storeData,
    { currentCycle, pickingPeriodOpen } = store;
  state.store.data.currentCycle = currentCycle;
  const hasRenewed = state.accountHistory?.find(
    (ah) =>
      ah?.cycleId == currentCycle?.id &&
      ah?.status !== "Cancelled" &&
      !ah?.deletedAt &&
      ah?.type === "Subscription" &&
      ah?.origin === "Business Process",
  );
  const showFlexSelectionsNewCopy =
    state.appFeatures?.find((f) => f?.feature == "Selection count BNB")
      ?.status == "Active";
  return {
    account: state.account,
    currentCycle: currentCycle,
    policy: state.policy,
    hasRenewed,
    pickingPeriodOpen: pickingPeriodOpen,
    creditNavStatus: state.creditNavStatus.status,
    isYearlyMember: isYearlyMember(state),
    overlay: state.ui ? state.ui.overlay : null,
    path: state.analytics.location,
    currentMonth: currentCycle.label,
    showFlexSelectionsNewCopy,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ push, setCreditNavStatus, setOverlay }, dispatch);
}

export default withRegion(
  withStoreData(connect(mapStateToProps, mapDispatchToProps)(CreditsDashboard)),
);
